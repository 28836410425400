// USA
export const locale = {
  HEADER: {
    HI: "హాయ్ ",
    SIGN_OUT: "సైన్ అవుట్ చేయండి"
  },
  MENU: {
    DASHBOARD: "డాష్బోర్డ్",
    TRANSACTION: "లావాదేవీ",
    RECENT_TRANSACTION: "ఇటీవలి లావాదేవీ",
    DEPOSIT: "డిపాజిట్",
    WITHDRAWAL: "ఉపసంహరణ",
    AGENT_LIST: "ఏజెంట్ జాబితా",
    CLIENT_LIST: "క్లయింట్ జాబితా",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "ఆపరేటర్ల జాబితా",
    CURRENCY_LIST: "కరెన్సీ జాబితా",
    SETTING: "సెట్టింగ్స్",
    ROLE: "పాత్ర"
  },
  LOGIN: {
    USERNAME: "వినియోగదారు పేరు",
    PASSWORD: "పాస్వర్డ్",
    BUTTON: "బటన్"
  },
  TABLE: {
    INDEX: "ఇండెక్స్",
    CLIENT_ID: "క్లయింట్ ఐడి ",
    NAME: "పేరు ",
    USERNAME: "వినియోగదారు పేరు",
    BALANCE: "సంతులనం",
    COMMISSION: "కమిషన్",
    REQUEST: "అభ్యర్థన",
    STATUS: "స్థితి",
    FAILED: "విఫలమైంది",
    SUCCESS: "విజయం",
    PENDING: "పెండింగ్‌లో ఉంది",
    REJECTED: "తిరస్కరించబడింది",
    PROCESSING: "ప్రాసెసింగ్",
    AMOUNT: "మొత్తం",
    RECEIVER_ADDRESS: "రిసీవర్ చిరునామా",
    NETWORK: "నెట్‌వర్క్",
    TRX_ID: "TRX ఐడి ",
    API_KEY: "API కీ",
    SECRET_KEY: "రహస్య కీ",
    SENDER_ADDRESS: "సెండర్   అడ్రస్ ",
    TOKEN_TYPE: "టోకెన్ రకం",
    TOKEN_NAME: "టోకెన్ పేరు",
    TYPE: "రకం",
    DATE_TIME: "తేదీ సమయం",
    PER_PAGE: "ప్రతి పేజీకి",
    ACTION: "చర్య",
    READ: "చదవండి",
    CREATE: "సృష్టించు",
    UPDATE: "నవీకరణ",
    DELETE: "తొలగించు",
    REMARKS: "వ్యాఖ్యలు",
    BLOCK_ID: "బ్లాక్ ఐడి ",
    WALLET_ADDRESS: "వాలెట్ చిరునామా",
    RECEIVE_AMOUNT: "మొత్తాన్ని స్వీకరించండి",
    UPDATED_AT: "వద్ద నవీకరించబడింది",
    CREATED_AT: "వద్ద సృష్టించబడింది",
    ACCESS_KEY: "యాక్సెస్ కీ",
    NO_DATA: "సమాచారం లేదు",
    ACTIVE: "యాక్టివ్",
    BANNED: "నిషేధించబడింది",
    INACTIVE: "నిష్క్రియాత్మకమైనది",
    ENABLE: "ప్రారంభించు",
    DISABLE: "డిసేబుల్",
    VIEW_ALL: "అన్నింటినీ వీక్షించండి",
    COPY_ACCESS: "కాపీ యాక్సెస్",
    ENABLE_2FA: "2FAను ప్రారంభించండి",
    COPY_CLIPBOARD: "క్లిప్‌బోర్డ్‌ను కాపీ చేయండి"
  },
  DASHBOARD: {
    AMOUNT_TRX: "మొత్తం TRX",
    AMOUNT_WITHDRAW: "మొత్తం విత్‌డ్రా",
    NUMBER_TRX: "నెంబర్  TRX",
    BALANCE: "సంతులనం"
  },
  SETTING: {
    CHANGE_PASS: "పాస్ మార్చండి",
    UPDATE_GAS_FEE: "గ్యాస్ ఫీజును నవీకరించండి",
    GAS_FEE: "గ్యాస్ ఫీజు",
    NEW_PASSWORD: "కొత్త పాస్వర్డ్",
    CONFIRM_PASSWORD: "పాస్‌వర్డ్‌ని నిర్ధారించండి",
    ENTER_NEW_PASSWORD: "కొత్త పాస్‌వర్డ్‌ని నమోదు చేయండి",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "నెట్‌వర్క్ ఫీజును చేర్చండి"
  },
  TRANSACTION: {
    TRANS_LIST: "ట్రాన్స్ లిస్ట్",
    START_UP_DATE: "నవీకరణ మొదలుపెట్టండి",
    END_UP_DATE: "తేదీని ముగించండి",
    SEARCH_FILTER: "శోధన ఫిల్టర్",
    SEARCH: "వెతకండి",
    NO_DATE: "తేదీ కాదు",
    SENDER_ADDRESS: "సెండర్  అడ్రస్",
    TOKEN_TYPE: "టోకెన్ రకం",
    TOKEN_NAME: "టోకెన్ పేరు",
    STATUS: "స్థితి",
    ALL: "అన్ని",
    PENDING: "పెండింగ్‌లో ఉంది",
    SUCCESS: "విజయం",
    FAILED: "విఫలమైంది",
    EXPORT: "ఎగుమతి"
  },
  MODAL: {
    OK: "అలాగే",
    CANCEL: "రద్దు చేయండి",
    CREATE_SUCESSFULL: "విజయవంతంగా సృష్టించండి",
    UPDATE_SUCESSFULL: "అప్‌డేట్ సక్సెస్ ఫుల్",
    DELETE_CONFIRMATION: "నిర్ధారణను తొలగించండి",
    DELETE_SUCESSFULL: "విజయవంతంగా తొలగించండి",
    FIELD_REQUIRED: "ఫీల్డ్ అవసరం",
    FIELD_REQUIRED_NUMBER: "ఫీల్డ్ అవసరమైన సంఖ్య",
    FIELD_REQUIRED_DECIMAL: "ఫీల్డ్ డెసిమల్ అవసరం"
  },
  TIER: {
    CONFIRM_PASSWORD: "పాస్‌వర్డ్‌ని నిర్ధారించండి",
    ENTER_PASSWORD: "రహస్య సంకేతం తెలపండి",
    ENTER_PERCENTAGE: "శాతాన్ని నమోదు చేయండి",
    ENTER_NAME: "పేరు నమోదు చేయండి",
    ENTER_USERNAME: "యూసర్నేమ్ ని నమోదు చేయండి",
    WILL_GENERATE: "ఉత్పత్తి అవుతుంది",
    UPDATE: "నవీకరణ",
    CREATE: "సృష్టించు",
    CLIENT: "క్లయింట్",
    OPERATOR: "ఆపరేటర్"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "మొత్తాన్ని నమోదు చేయండి",
    ENTER_RECEIVER: "రిసీవర్‌ని నమోదు చేయండి",
    CURRENCY: "కరెన్సీ",
    WITHDRAWAL_FEE: "ఉపసంహరణ రుసుము",
    REQ_NEW_WITHDRAW: "REQ కొత్త ఉపసంహరణ",
    UPDATE_STATUS: "స్థితిని నవీకరించండి",
    TRX_ID: "TRX ఐడి",
    ENTER_TRX_ID: "TRX ఐడి ని నమోదు చేయండి",
    START_UP_DATE: "నవీకరణ మొదలుపెట్టండి",
    END_UP_DATE: "తేదీని ముగించండి",
    SEARCH: "వెతకండి",
    NO_DATE: "తేదీ కాదు",
    RECEIVER_ADDRESS: "రిసీవర్ చిరునామా",
    TRANS_ID: "TRANS ఐడి",
    NETWORK: "నెట్‌వర్క్",
    STATUS: "స్థితి",
    ALL: "అన్ని",
    PENDING: "పెండింగ్‌లో ఉంది",
    SUCCESS: "విజయం",
    FAILED: "విఫలమైంది",
    REJECTED: "తిరస్కరించబడింది",
    PROCESSING: "ప్రాసెసింగ్",
    EXPORT: "ఎగుమతి",
    AMOUNT_VALIDATION: "అమౌంట్ ధ్రువీకరణ"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "దయచేసి స్కాన్ చేయండి",
    UNABLE_SCAN: "స్కాన్ చేయలేనిది",
    SAVE_KEY: "కీని సేవ్ చేయండి",
    RECOVER: "కోలుకోండి",
    VERIFY_ACCOUNT: "ఖాతాను ధృవీకరించండి",
    ENTER_DIGIT: "అంకెలను నమోదు చేయండి",
    VALIDATION_VERIFICATION_CODE: "ధృవీకరణ ధృవీకరణ కోడ్",
    INVALID_CODE: "చెల్లని కోడ్",
    VALIDATOR_ENABLED: "వాలిడేటర్ ప్రారంభించబడింది",
    VALIDATOR_DISABLED: "వాలిడేటర్ డిజేబుల్ చేయబడింది",
    VALIDATION_CONFIRM_PASSWORD: "ధృవీకరణ పాస్‌వర్డ్‌ని నిర్ధారించండి"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "ధృవీకరణ నిలిపివేయబడింది",
    CONFIRMATION_ENABLE: "కన్ఫర్మేషన్ ఎనేబుల్"
  },
  ROLE: {
    ACCESS: "యాక్సెస్"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "వినియోగదారు పేరు కనుగొనబడలేదు",
    SERVER_ERROR: "సర్వర్లో ఏదో లోపం ఉంది",
    DATA_NOT_FOUND: "డేటా కనుగొనబడలేదు",
    INSUFFICENT_BALANCE: "సరిపోని బ్యాలెన్స్",
    CLIENT_NOT_FOUND: "క్లయింట్ డేటా కనుగొనబడలేదు",
    USER_PASS_INCORRECT: "వినియోగదారు పేరు లేదా పాస్వర్డ్ తప్పు",
    USERNAME_EXIST: "యూసర్ నేమ్  ఉనికిలో ఉన్నారు"
  }
};
