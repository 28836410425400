import ApiService from "@/core/services/api.service";

// action types
export const GET_GAS_FEE = "getGasFee";
export const UPDATE_GAS_FEE = "updateGasFee";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  deposit: {}
};

const getters = {};

const actions = {
  [GET_GAS_FEE](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/global-setting/admin", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_GAS_FEE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/global-setting/admin/update", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
