// USA
export const locale = {
  HEADER: {
    HI: "こんにちは、",
    SIGN_OUT: "サインアウト"
  },
  MENU: {
    DASHBOARD: "ダッシュボード",
    TRANSACTION: "取引内容",
    RECENT_TRANSACTION: "直近の取引内容",
    DEPOSIT: "ご入金",
    WITHDRAWAL: "お引き出し",
    AGENT_LIST: "エージェントリスト",
    CLIENT_LIST: "クライアントリスト",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "オペレーターリスト",
    CURRENCY_LIST: "通貨リスト",
    SETTING: "設定",
    ROLE: "役職"
  },
  LOGIN: {
    USERNAME: "ユーザー名",
    PASSWORD: "パスワード",
    BUTTON: "サインイン"
  },
  TABLE: {
    INDEX: "目次",
    CLIENT_ID: "クライアントID",
    NAME: "名前",
    USERNAME: "ユーザー名",
    BALANCE: "残高",
    COMMISSION: "コミッション",
    REQUEST: "リクエスト",
    STATUS: "ステータス",
    FAILED: "失敗",
    SUCCESS: "成功",
    PENDING: "保留",
    REJECTED: "拒否",
    PROCESSING: "処理中",
    AMOUNT: "金額",
    RECEIVER_ADDRESS: "受取人住所",
    NETWORK: "ネットワーク",
    TRX_ID: "取引ID",
    API_KEY: "APIキー",
    SECRET_KEY: "シークレットキー",
    SENDER_ADDRESS: "送信者住所",
    TOKEN_TYPE: "トークンタイプ",
    TOKEN_NAME: "トークン名",
    TYPE: "タイプ",
    DATE_TIME: "日時",
    PER_PAGE: "ページにつき",
    ACTION: "アクション",
    READ: "リード",
    CREATE: "クリエイト",
    UPDATE: "アップデート",
    DELETE: "削除",
    REMARKS: "備考",
    BLOCK_ID: "ブロックID",
    WALLET_ADDRESS: "ウォレットアドレス",
    RECEIVE_AMOUNT: "受取金額",
    UPDATED_AT: "アップデートAt",
    CREATED_AT: "クリエイトAt",
    ACCESS_KEY: "アクセスキー",
    NO_DATA: "データ無し",
    ACTIVE: "アクティブ",
    BANNED: "禁止",
    INACTIVE: "非アクティブ",
    ENABLE: "有効",
    DISABLE: "無効",
    VIEW_ALL: "全て表示",
    COPY_ACCESS: "コピーアクセス",
    ENABLE_2FA: "有効 2fa",
    COPY_CLIPBOARD: "クリップボードにコピーされました。"
  },
  DASHBOARD: {
    AMOUNT_TRX: "一日の合計取引額",
    AMOUNT_WITHDRAW: "一日の合計引出額",
    NUMBER_TRX: "一日の合計取引数",
    BALANCE: "CrimsonPayの合計残高"
  },
  SETTING: {
    CHANGE_PASS: "パスワードの変更",
    UPDATE_GAS_FEE: "ガス料金の更新",
    GAS_FEE: "ガス料金",
    NEW_PASSWORD: "新しいパスワード",
    CONFIRM_PASSWORD: "新しいパスワードの確認",
    ENTER_NEW_PASSWORD: "新しいパスワードの入力",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "ネットワーク料金が含まれています。"
  },
  TRANSACTION: {
    TRANS_LIST: "取引リスト",
    START_UP_DATE: "更新開始日",
    END_UP_DATE: "更新終了日",
    SEARCH_FILTER: "検索（trx ID、ブロックID、金額）",
    SEARCH: "検索",
    NO_DATE: "日付指定なし",
    SENDER_ADDRESS: "送信者住所",
    TOKEN_TYPE: "トークンタイプ",
    TOKEN_NAME: "トークン名",
    STATUS: "ステータス",
    ALL: "全て",
    PENDING: "保留",
    SUCCESS: "成功",
    FAILED: "失敗",
    EXPORT: "エクスポート"
  },
  MODAL: {
    OK: "OK",
    CANCEL: "キャンセル",
    CREATE_SUCESSFULL: "作成が完了しました。",
    UPDATE_SUCESSFULL: "更新が完了しました。",
    DELETE_CONFIRMATION: "削除してよろしいですか？",
    DELETE_SUCESSFULL: "削除が完了しました。",
    FIELD_REQUIRED: "この項目は必須です。",
    FIELD_REQUIRED_NUMBER: "この項目は必須です。有効な正数を入力してください。",
    FIELD_REQUIRED_DECIMAL: "この項目は必須です。0.1以上を入力してください。"
  },
  TIER: {
    CONFIRM_PASSWORD: "新しいパスワードの確認",
    ENTER_PASSWORD: "パスワードを入力",
    ENTER_PERCENTAGE: "割合を入力",
    ENTER_NAME: "お名前を入力",
    ENTER_USERNAME: "ユーザー名を入力",
    WILL_GENERATE: "自動的に生成されます。",
    UPDATE: "アップデート",
    CREATE: "作成",
    CLIENT: "クライアント",
    OPERATOR: "オペレーター"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "金額を入力",
    ENTER_RECEIVER: "受取人住所を入力",
    CURRENCY: "通貨",
    WITHDRAWAL_FEE: "引出手数料",
    REQ_NEW_WITHDRAW: "新しい引出しリクエスト",
    UPDATE_STATUS: "ステータスの更新",
    TRX_ID: "取引ID",
    ENTER_TRX_ID: "取引IDを入力",
    START_UP_DATE: "更新開始日",
    END_UP_DATE: "更新終了日",
    SEARCH: "検索",
    NO_DATE: "日付が選択されていません。",
    RECEIVER_ADDRESS: "受取人住所",
    TRANS_ID: "取引ID",
    NETWORK: "ネットワーク",
    STATUS: "ステータス",
    ALL: "全て",
    PENDING: "保留",
    SUCCESS: "成功",
    FAILED: "失敗",
    REJECTED: "拒否",
    PROCESSING: "処理中",
    EXPORT: "エクスポート",
    AMOUNT_VALIDATION: "お引出し手数料以上の金額にする必要があります。"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "Google認証システムでコードをスキャンするか入力してください。",
    UNABLE_SCAN:
      "QRコードをスキャンできない場合は、このコードをアプリに手動で入力してください。",
    SAVE_KEY: "このバックアップキーを安全な場所に保存します。",
    RECOVER:
      "このキーを使用すると電話を紛失した場合に認証システムを回復できます。またGoogle認証システムのリセットには少なくとも7日かかります。",
    VERIFY_ACCOUNT: "アカウントを確認する",
    ENTER_DIGIT: "Google認証システムからの6桁のコードを入力します。",
    VALIDATION_VERIFICATION_CODE: "認証コードは6桁の数字である必要があります。",
    INVALID_CODE: "無効なGoogle認証システムコードです。再度お試しください",
    VALIDATOR_ENABLED: "2FAが有効になりました！",
    VALIDATOR_DISABLED: "2FAが無効になりました！",
    VALIDATION_CONFIRM_PASSWORD: "確認用パスワードがパスワードと一致しません。"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "無効としてよろしいですか？",
    CONFIRMATION_ENABLE: "有効としてよろしいですか？"
  },
  ROLE: {
    ACCESS: "アクセス"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "ユーザー名が確認できません",
    SERVER_ERROR: "サーバーに問題があります",
    DATA_NOT_FOUND: "データが確認できません",
    INSUFFICENT_BALANCE: "残高不足",
    CLIENT_NOT_FOUND: "お客様情報が確認できません",
    USER_PASS_INCORRECT: "ユーザー名またはパスワードが正しくありません",
    USERNAME_EXIST:
      "ユーザー名が既に存在しています。別のユーザー名をお試しください。"
  }
};
