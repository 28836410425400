import OperatorService from "@/core/services/api.service";

// action types
export const GET_OPERATOR = "getOperator";
export const CREATE_OPERATOR = "createOperator";
export const UPDATE_OPERATOR = "updateOperator";
export const DELETE_OPERATOR = "deleteOperator";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  deposit: {}
};

const getters = {};

const actions = {
  [GET_OPERATOR](context, data) {
    return new Promise((resolve, reject) => {
      OperatorService.query("/client/admin/operator", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [CREATE_OPERATOR](context, payload) {
    return new Promise((resolve, reject) => {
      OperatorService.post("/client/admin/operator", payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_OPERATOR](context, payload) {
    return new Promise((resolve, reject) => {
      OperatorService.put(
        "/client/admin/operator/" + payload.username,
        payload.data
      )
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [DELETE_OPERATOR](context, payload) {
    return new Promise((resolve, reject) => {
      OperatorService.delete("/client/admin/operator/" + payload.username)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
