import ApiService from "@/core/services/api.service";

// action types
export const GET_API = "getApi";
export const UPDATE_PASS = "updatePass";
export const TOGGLE_2FA = "toggle2FA";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  deposit: {}
};

const getters = {};

const actions = {
  [GET_API](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/client/admin/" + data.username)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_PASS](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.put("/client/admin/" + data.username, {
        password: data.password
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [TOGGLE_2FA](context, data) {
    return new Promise(resolve => {
      ApiService.post("setting/2fa", data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
