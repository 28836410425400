// USA
export const locale = {
  HEADER: {
    HI: "Halo,",
    SIGN_OUT: "Keluar"
  },
  MENU: {
    DASHBOARD: "Dasbor",
    TRANSACTION: "Transaksi",
    RECENT_TRANSACTION: "Transaksi terakhir",
    DEPOSIT: "Deposit",
    WITHDRAWAL: "Withdraw",
    AGENT_LIST: "Daftar agen",
    CLIENT_LIST: "Daftar klien",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "Daftar operator",
    CURRENCY_LIST: "Daftar Aset",
    SETTING: "Pengaturan",
    ROLE: "Peran"
  },
  LOGIN: {
    USERNAME: "Nama pengguna",
    PASSWORD: "Sandi",
    BUTTON: "Masuk"
  },
  TABLE: {
    INDEX: "Indeks",
    CLIENT_ID: "Identitas klien",
    NAME: "Nama",
    USERNAME: "Nama Pengguna",
    BALANCE: "Saldo",
    COMMISSION: "Komisi",
    REQUEST: "Permohonan",
    STATUS: "Status",
    FAILED: "Gagal",
    SUCCESS: "Sukses",
    PENDING: "Ditunda",
    REJECTED: "Ditolak",
    PROCESSING: "Diproses",
    AMOUNT: "Jumlah",
    RECEIVER_ADDRESS: "Alamat penerima",
    NETWORK: "Jaringan",
    TRX_ID: "Identitas Transaksi",
    API_KEY: "API Key",
    SECRET_KEY: "API Secret",
    SENDER_ADDRESS: "Alamat pengirim",
    TOKEN_TYPE: "Jenis token",
    TOKEN_NAME: "Nama token",
    TYPE: "Jenis",
    DATE_TIME: "Tanggal dan waktu",
    PER_PAGE: "Per halaman",
    ACTION: "Aksi",
    READ: "Lihat",
    CREATE: "Buat",
    UPDATE: "Ubah",
    DELETE: "Hapus",
    REMARKS: "Catatan",
    BLOCK_ID: "ID Blok",
    WALLET_ADDRESS: "Alamat dompet",
    RECEIVE_AMOUNT: "Jumlah diterima",
    UPDATED_AT: "Di ubah pada",
    CREATED_AT: "Di buat pada",
    ACCESS_KEY: "Key Akses",
    NO_DATA: "Tidak ada data",
    ACTIVE: "Aktif",
    BANNED: "Di blokir",
    INACTIVE: "Tidak aktif",
    ENABLE: "Aktifkan",
    DISABLE: "Non aktifkan",
    VIEW_ALL: "Lihat semua",
    COPY_ACCESS: "Salin akses",
    ENABLE_2FA: "Aktifkan 2fa",
    COPY_CLIPBOARD: "Berhasil disalin ke papan klip"
  },
  DASHBOARD: {
    AMOUNT_TRX: "Jumlah seluruh transaksi harian",
    AMOUNT_WITHDRAW: "Jumlah seluruh penarikan harian",
    NUMBER_TRX: "Banyak transaksi harian",
    BALANCE: "Jumlah saldo di CrimsonPay"
  },
  SETTING: {
    CHANGE_PASS: "Ubah sandi",
    UPDATE_GAS_FEE: "Ubah Gas Fee",
    GAS_FEE: "Gas Fee",
    NEW_PASSWORD: "Sandi baru",
    CONFIRM_PASSWORD: "Konfirmasi sandi baru",
    ENTER_NEW_PASSWORD: "Masukan sandi baru",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "Termasuk biaya jaringan"
  },
  TRANSACTION: {
    TRANS_LIST: "Daftar transaksi",
    START_UP_DATE: "Awal tanggal diubah",
    END_UP_DATE: "Akhir tanggal diubah",
    SEARCH_FILTER: "Cari (id transaksi, id blok, jumlah)",
    SEARCH: "Cari",
    NO_DATE: "Tanggal belum dipilih",
    SENDER_ADDRESS: "Alamat pengirim",
    TOKEN_TYPE: "Jenis token",
    TOKEN_NAME: "Nama token",
    STATUS: "Status",
    ALL: "Semua",
    PENDING: "Ditunda",
    SUCCESS: "Berhasil",
    FAILED: "Gagal",
    EXPORT: "Ekspor"
  },
  MODAL: {
    OK: "Ok",
    CANCEL: "Batalkan",
    CREATE_SUCESSFULL: "Berhasil dibuat",
    UPDATE_SUCESSFULL: "Berhasil diubah",
    DELETE_CONFIRMATION: "Anda yakin, akan menghapus",
    DELETE_SUCESSFULL: "Berhasil dihapus",
    FIELD_REQUIRED: "Kolom ini diperlukan",
    FIELD_REQUIRED_NUMBER: "Kolom ini diperlukan dan merupakan angka positif",
    FIELD_REQUIRED_DECIMAL: "Kolom ini diperlukan dan lebih besar dari 0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "Konfirmasi sandi baru",
    ENTER_PASSWORD: "Masukkan sandi",
    ENTER_PERCENTAGE: "Masukkan persentase",
    ENTER_NAME: "Masukkan nama",
    ENTER_USERNAME: "Masukkan nama akun",
    WILL_GENERATE: "Akan di buat otomatis",
    UPDATE: "Ubah",
    CREATE: "Buat",
    CLIENT: "Klien",
    OPERATOR: "Operator"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "Masukkan jumlah",
    ENTER_RECEIVER: "Masukkan alamat penerima",
    CURRENCY: "Mata uang",
    WITHDRAWAL_FEE: "Biaya penarikan",
    REQ_NEW_WITHDRAW: "Permintaan penarikan baru",
    UPDATE_STATUS: "Ubah status",
    TRX_ID: "ID transaksi",
    ENTER_TRX_ID: "Masukkan ID transaksi",
    START_UP_DATE: "Awal tanggal diubah",
    END_UP_DATE: "Akhir tanggal diubah",
    SEARCH: "Cari",
    NO_DATE: "Tanggal belum dipilih",
    RECEIVER_ADDRESS: "Alamat penerima",
    TRANS_ID: "ID transaksi",
    NETWORK: "Jaringan",
    STATUS: "Status",
    ALL: "Semua",
    PENDING: "Ditunda",
    SUCCESS: "Berhasil",
    FAILED: "Gagal",
    REJECTED: "Ditolak",
    PROCESSING: "Diproses",
    EXPORT: "Ekspor",
    AMOUNT_VALIDATION: "Jumlah harus lebih besar dari biaya penarikan"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN:
      "Silahkan di scan atau masukkan kode pada Google Authenticator.",
    UNABLE_SCAN:
      "Jika terdapat masalah saat melakukan scan, silahkan masukkan kode berikut pada aplikasi.",
    SAVE_KEY: "Simpan kode pemulihan berikut di tempat yang aman.",
    RECOVER:
      "Kode berikut dapat digunakan untuk memulihkan akun Authenticator jika ponsel anda hilang. Jika tidak, menyetel ulang Google Authenticator akan memakan waktu setidaknya 7 hari.",
    VERIFY_ACCOUNT: "Verifikasi akun anda",
    ENTER_DIGIT:
      "Masukkan 6 digit kode yang terdapat pada Google Authenticator",
    VALIDATION_VERIFICATION_CODE: "Kode verifikasi harus 6 digit",
    INVALID_CODE: "Kode Google authenticator tidak valid, silakan coba lagi",
    VALIDATOR_ENABLED: "2FA telah berhasil diaktifkan!",
    VALIDATOR_DISABLED: "2FA telah berhasil dinonaktifkan!",
    VALIDATION_CONFIRM_PASSWORD:
      "Konfirmasi kata sandi tidak cocok dengan kata sandi"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "Apakah anda yakin ingin menonaktifkan",
    CONFIRMATION_ENABLE: "Apakah anda yakin ingin mengaktifkan"
  },
  ROLE: {
    ACCESS: "Mengakses"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "Nama pengguna tidak ditemukan",
    SERVER_ERROR: "Ada yang salah dengan server",
    DATA_NOT_FOUND: "Data tidak ditemukan",
    INSUFFICENT_BALANCE: "Saldo tidak mencukupi",
    CLIENT_NOT_FOUND: "Data klien tidak ditemukan",
    USER_PASS_INCORRECT: "Nama pengguna atau kata sandi salah",
    USERNAME_EXIST: "Nama pengguna sudah ada, silakan coba nama lain"
  }
};
