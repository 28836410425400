// USA
export const locale = {
  HEADER: {
    HI: "Hola,",
    SIGN_OUT: "salir/desconectar"
  },
  MENU: {
    DASHBOARD: "Panel",
    TRANSACTION: "Transacción",
    RECENT_TRANSACTION: "Transacción reciente",
    DEPOSIT: "Deposito",
    WITHDRAWAL: "Retiro",
    AGENT_LIST: "Lista de agentes",
    CLIENT_LIST: "Lista de clientes",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "Lista de operadores",
    CURRENCY_LIST: "Lista de divisas",
    SETTING: "Configuración",
    ROLE: "Función"
  },
  LOGIN: {
    USERNAME: "Nombre de usuario",
    PASSWORD: "contraseña",
    BUTTON: "Entrar/Registrarse"
  },
  TABLE: {
    INDEX: "Índice",
    CLIENT_ID: "Identificación del cliente",
    NAME: "Nombre",
    USERNAME: "Nombre de usuario",
    BALANCE: "Saldo",
    COMMISSION: "Comisión",
    REQUEST: "Solicitud",
    STATUS: "Estado",
    FAILED: "FALLÓ",
    SUCCESS: "EXITOSO",
    PENDING: "PENDIENTE",
    REJECTED: "RECHAZADO",
    PROCESSING: "PROCESANDO",
    AMOUNT: "Cantidad",
    RECEIVER_ADDRESS: "Dirección de destino",
    NETWORK: "La red",
    TRX_ID: "ID TRANS",
    API_KEY: "Clave API",
    SECRET_KEY: "Llave secreta",
    SENDER_ADDRESS: "Dirección del remitente",
    TOKEN_TYPE: "Tipo de token",
    TOKEN_NAME: "Nombre de token",
    TYPE: "Tipo",
    DATE_TIME: "Fecha y hora",
    PER_PAGE: "Por página",
    ACTION: "Acción",
    READ: "Leer",
    CREATE: "Crear",
    UPDATE: "Actualizar",
    DELETE: "Borrar/eliminar",
    REMARKS: "Comentarios",
    BLOCK_ID: "Bloqueo del ID",
    WALLET_ADDRESS: "Dirección de billetera",
    RECEIVE_AMOUNT: "Recibir importe",
    UPDATED_AT: "Actualizado en",
    CREATED_AT: "Creado en",
    ACCESS_KEY: "Clave de acceso",
    NO_DATA: "Sin datos",
    ACTIVE: "ACTIVO",
    BANNED: "PROHIBIDO",
    INACTIVE: "Inactivo",
    ENABLE: "Habilitar",
    DISABLE: "Desactivar",
    VIEW_ALL: "Ver todo",
    COPY_ACCESS: "Acceso a la copia",
    ENABLE_2FA: "Habilitar 2FA",
    COPY_CLIPBOARD: "Ha sido copiado al portapapeles"
  },
  DASHBOARD: {
    AMOUNT_TRX: "Cantidad total diaria de transacciones.",
    AMOUNT_WITHDRAW: "Cantidad total diaria de retiros.",
    NUMBER_TRX: "Número total diario de transacción",
    BALANCE: "Cantidad total de saldo en CrimsonPay"
  },
  SETTING: {
    CHANGE_PASS: "Cambia la contraseña",
    UPDATE_GAS_FEE: "Actualizar la tarifa de gas",
    GAS_FEE: "Tarifa de gas",
    NEW_PASSWORD: "Nueva contraseña",
    CONFIRM_PASSWORD: "Confirmar nueva contraseña",
    ENTER_NEW_PASSWORD: "Introduzca una nueva contraseña",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "Incluir tarifa de red"
  },
  TRANSACTION: {
    TRANS_LIST: "Lista de transacciones",
    START_UP_DATE: "Inicio Fecha actualizada",
    END_UP_DATE: "Fecha de la actualización final",
    SEARCH_FILTER: "Búsqueda (ID de TRX, ID de bloque, cantidad)",
    SEARCH: "Búsqueda",
    NO_DATE: "No hay fecha seleccionada",
    SENDER_ADDRESS: "Dirección del remitente",
    TOKEN_TYPE: "Tipo de token",
    TOKEN_NAME: "Nombre de token",
    STATUS: "Estado",
    ALL: "TODOS",
    PENDING: "PENDIENTE",
    SUCCESS: "ÉXITO",
    FAILED: "HA FALLADO",
    EXPORT: "Exportar"
  },
  MODAL: {
    OK: "OK",
    CANCEL: "Cancelar",
    CREATE_SUCESSFULL: "Creado con exito",
    UPDATE_SUCESSFULL: "La actualización es exitosa",
    DELETE_CONFIRMATION: "¿Estás seguro, que quieres borrar?",
    DELETE_SUCESSFULL: "Eliminado exitosamente",
    FIELD_REQUIRED: "Este campo es obligatorio",
    FIELD_REQUIRED_NUMBER:
      "Este campo es obligatorio y debe ser un número positivo válido",
    FIELD_REQUIRED_DECIMAL: "Este campo es obligatorio y debe ser mayor que 0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "Confirmar nueva contraseña",
    ENTER_PASSWORD: "Introducir la contraseña",
    ENTER_PERCENTAGE: "Ingrese porcentaje",
    ENTER_NAME: "Ingrese su nombre",
    ENTER_USERNAME: "Introduzca su nombre de usuario",
    WILL_GENERATE: "Será generado automáticamente",
    UPDATE: "Actualizar",
    CREATE: "Crear",
    CLIENT: "Cliente",
    OPERATOR: "Operador"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "Ingrese la cantidad",
    ENTER_RECEIVER: "Ingrese la dirección del destinatario",
    CURRENCY: "Moneda",
    WITHDRAWAL_FEE: "Cargo por retiro",
    REQ_NEW_WITHDRAW: "Solicitar nueva retirada",
    UPDATE_STATUS: "Estado de actualización",
    TRX_ID: "ID de transaccion",
    ENTER_TRX_ID: "Ingrese la identificación de la transacción",
    START_UP_DATE: "Fecha de inicio de actualizacion",
    END_UP_DATE: "Fecha de actualizacion final",
    SEARCH: "Búsqueda",
    NO_DATE: "Ninguna fecha seleccionada",
    RECEIVER_ADDRESS: "Dirección del destinatario",
    TRANS_ID: "ID de transaccion",
    NETWORK: "Red",
    STATUS: "Estado",
    ALL: "TODOS",
    PENDING: "PENDIENTE",
    SUCCESS: "ÉXITO",
    FAILED: "HA FALLADO",
    REJECTED: "RECHAZADO",
    PROCESSING: "PROCESANDO",
    EXPORT: "Exportar",
    AMOUNT_VALIDATION: "La cantidad debe ser mayor que la tasa de retiro"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN:
      "Por favor, escanee o ingrese el código en su autenticador de Google:",
    UNABLE_SCAN:
      "Si no puede escanear el código QR, ingrese este código manualmente en la aplicación.",
    SAVE_KEY: "Guarde esta tecla de respaldo en una ubicación segura",
    RECOVER:
      "Esta clave le permitirá recuperar su autenticador si pierde su teléfono. De lo contrario, restablecer el autenticador de Google tomará al menos 7 días.",
    VERIFY_ACCOUNT: "Verifica tu cuenta",
    ENTER_DIGIT: "Ingrese el código de 6 dígitos de Google Autenticator",
    VALIDATION_VERIFICATION_CODE:
      "El código de verificación debe ser un número de 6 dígitos",
    INVALID_CODE:
      "Código de autenticador de Google no válido, inténtalo de nuevo",
    VALIDATOR_ENABLED: "2FA se ha habilitado con éxito!",
    VALIDATOR_DISABLED: "2FA ha sido desactivado con éxito!",
    VALIDATION_CONFIRM_PASSWORD:
      "Confirmar contraseña no coincide con la contraseña"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "¿Estás seguro, quieres deshabilitar?",
    CONFIRMATION_ENABLE: "¿Estás seguro, quieres habilitar?"
  },
  ROLE: {
    ACCESS: "Acceso"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "Nombre de usuario no encontrado",
    SERVER_ERROR: "Hay un problema con el servidor",
    DATA_NOT_FOUND: "Datos no encontrados",
    INSUFFICENT_BALANCE: "Saldo insuficiente",
    CLIENT_NOT_FOUND: "No se han encontrado los datos del cliente",
    USER_PASS_INCORRECT: "El nombre de usuario o la contraseña son incorrectos",
    USERNAME_EXIST: "El nombre de usuario ya existe, por favor, intente otro.",
  }
};
