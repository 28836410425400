import ApiService from "@/core/services/api.service";

// action types
export const GET_ROLE = "getRole";
export const GET_ROLE_DETAIL = "getRoleDetail";
export const CREATE_ROLE = "createRole";
export const UPDATE_ROLE = "updateRole";
export const DELETE_ROLE = "deleteRole";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  role: {}
};

const getters = {};

const actions = {
  [GET_ROLE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/admin/access/role")
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [GET_ROLE_DETAIL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.get("/admin/access/resources/" + payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [CREATE_ROLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/admin/access", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_ROLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/admin/access/" + payload.id, {
        role: payload.role,
        resources: payload.resources
      })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [DELETE_ROLE](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.delete("/admin/access/role/" + payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
