// China
export const locale = {
  HEADER: {
    HI: "嗨,",
    SIGN_OUT: "登出"
  },
  MENU: {
    DASHBOARD: "控制中心",
    TRANSACTION: "交易",
    RECENT_TRANSACTION: "最近交易",
    DEPOSIT: "存款",
    WITHDRAWAL: "提款",
    AGENT_LIST: "代理列表",
    CLIENT_LIST: "客户列表",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "操作者名单",
    CURRENCY_LIST: "币种列表",
    SETTING: "设置",
    ROLE: "角色"
  },
  LOGIN: {
    USERNAME: "用户名",
    PASSWORD: "密码",
    BUTTON: "登录"
  },
  TABLE: {
    INDEX: "索引",
    CLIENT_ID: "客户编号",
    NAME: "名字",
    USERNAME: "用户名",
    BALANCE: "余额",
    COMMISSION: "抽佣",
    STATUS: "状态",
    FAILED: "失败",
    SUCCESS: "成功",
    PENDING: "待定",
    REJECTED: "拒绝",
    PROCESSING: "处理中",
    AMOUNT: "金额 ",
    RECEIVER_ADDRESS: "收款地址",
    NETWORK: "网络",
    TRX_ID: "交易编号",
    REQUEST: "请求",
    API_KEY: "Api密钥",
    SECRET_KEY: "密钥",
    SENDER_ADDRESS: "来源地址",
    TOKEN_TYPE: "货币类型",
    TOKEN_NAME: "货币名称",
    TYPE: "类型",
    DATE_TIME: "日期时间",
    PER_PAGE: "每页",
    ACTION: "操作",
    READ: "读取",
    CREATE: "创建",
    UPDATE: "更新",
    DELETE: "删除",
    REMARKS: "注备",
    BLOCK_ID: "Block编号",
    WALLET_ADDRESS: "钱包地址",
    RECEIVE_AMOUNT: "收款总额",
    UPDATED_AT: "更新时间于",
    CREATED_AT: "创建时间于",
    ACCESS_KEY: "访问密钥",
    NO_DATA: "无数据",
    ACTIVE: "活跃",
    BANNED: "活跃",
    INACTIVE: "不活跃",
    ENABLE: "开启",
    DISABLE: "关闭",
    VIEW_ALL: "显示全部",
    COPY_ACCESS: "复制权限",
    ENABLE_2FA: "开启多重认证",
    COPY_CLIPBOARD: "已复制到粘贴板"
  },
  DASHBOARD: {
    AMOUNT_TRX: "今日交易总额",
    AMOUNT_WITHDRAW: "今日提款总额",
    NUMBER_TRX: "今日交易次数",
    BALANCE: "总余额"
  },
  SETTING: {
    CHANGE_PASS: "更改密码",
    UPDATE_GAS_FEE: "更新矿工费",
    GAS_FEE: "矿工费",
    NEW_PASSWORD: "新密码",
    CONFIRM_PASSWORD: "确认新密码",
    ENTER_NEW_PASSWORD: "输入新密码",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "包含手续费"
  },
  TRANSACTION: {
    TRANS_LIST: "交易列表",
    START_UP_DATE: "开始日期",
    END_UP_DATE: "结束日期",
    SEARCH_FILTER: "搜索(trx编号，block编号，金额)",
    SEARCH: "搜索",
    NO_DATE: "未选择日期",
    SENDER_ADDRESS: "来源地址",
    TOKEN_NAME: "货币名称",
    TOKEN_TYPE: "货币类型",
    STATUS: "状态",
    ALL: "全部",
    PENDING: "待定",
    SUCCESS: "成功",
    FAILED: "失败",
    EXPORT: "导出"
  },
  MODAL: {
    OK: "确认",
    CANCEL: "取消",
    CREATE_SUCESSFULL: "创建成功",
    UPDATE_SUCESSFULL: "更新成功",
    DELETE_CONFIRMATION: "确认删除吗",
    DELETE_SUCESSFULL: "删除成功",
    FIELD_REQUIRED: "必填",
    FIELD_REQUIRED_NUMBER: "必填，且必须为有效正数",
    FIELD_REQUIRED_DECIMAL: "必填，且必须大于0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "确认新密码",
    ENTER_PASSWORD: "输入密码",
    ENTER_PERCENTAGE: "输入比例",
    ENTER_NAME: "输入名字",
    ENTER_USERNAME: "输入用户名",
    WILL_GENERATE: "会自动生成",
    UPDATE: "更新",
    CREATE: "创建",
    CLIENT: "客户",
    OPERATOR: "操作者"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "输入金额",
    ENTER_RECEIVER: "输入收款地址",
    CURRENCY: "币种",
    WITHDRAWAL_FEE: "提款费",
    REQ_NEW_WITHDRAW: "申请新提款",
    UPDATE_STATUS: "更新状态",
    TRX_ID: "交易编号",
    ENTER_TRX_ID: "输入交易编号",
    START_UP_DATE: "开始日期",
    END_UP_DATE: "结束日期",
    SEARCH: "搜索",
    NO_DATE: "未选择日期",
    RECEIVER_ADDRESS: "收款地址",
    TRANS_ID: "交易编号",
    NETWORK: "网络",
    STATUS: "状态",
    ALL: "全部",
    PENDING: "待定",
    SUCCESS: "成功",
    FAILED: "失败",
    REJECTED: "拒绝",
    PROCESSING: "处理中",
    EXPORT: "导出",
    AMOUNT_VALIDATION: "金额必须大于提款费用"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "请扫描或者输入谷歌验证码:",
    UNABLE_SCAN: "如果您能扫描此二维码，请在应用内手动输入此代码",
    SAVE_KEY: "请将备份密钥存储在安全的地方",
    RECOVER:
      "此密钥可以在您丢失手机时让您恢复验证器，否则重设谷歌验证器将需要至少7天",
    VERIFY_ACCOUNT: "验证您的账户",
    ENTER_DIGIT: "请输入您的6位谷歌验证码",
    VALIDATION_VERIFICATION_CODE: "验证码必须为6位数",
    INVALID_CODE: "无效谷歌验证码，请重试",
    VALIDATOR_ENABLED: "多重验证开启成功",
    VALIDATOR_DISABLED: "多重验证关闭成功",
    VALIDATION_CONFIRM_PASSWORD: "确认密码不符合"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "确认关闭吗",
    CONFIRMATION_ENABLE: "确认开启吗"
  },
  ROLE: {
    ACCESS: "权限"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "用户名未找到",
    SERVER_ERROR: "服务器出错",
    DATA_NOT_FOUND: "数据丢失",
    INSUFFICENT_BALANCE: "余额不足",
    CLIENT_NOT_FOUND: "客户数据丢失",
    USER_PASS_INCORRECT: "用户名或密码不正确",
    USERNAME_EXIST: "用户名已存在，请重试"
  }
};
