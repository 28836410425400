import Vue from "vue";
import Router from "vue-router";
import store from "./core/services/store";

Vue.use(Router);

const router = new Router({
  routes: [
    {
      path: "/",
      redirect: "/dashboard",
      component: () => import("@/view/layout/Layout"),
      children: [
        {
          path: "/dashboard",
          name: "dashboard",
          component: () => import("@/view/pages/Dashboard.vue")
        },
        {
          path: "/transaction",
          name: "transaction-list",
          component: () => import("@/view/pages/transaction")
        },
        {
          path: "/deposit",
          name: "deposit-list",
          component: () => import("@/view/pages/deposit")
        },
        {
          path: "/withdrawal",
          name: "withdrawal",
          component: () => import("@/view/pages/withdrawal")
        },
        {
          path: "/tier",
          name: "tier",
          component: () => import("@/view/pages/client")
        },
        {
          path: "/operator",
          name: "operator",
          component: () => import("@/view/pages/operator")
        },
        {
          path: "/currency",
          name: "currency",
          component: () => import("@/view/pages/currency")
        },
        {
          name: "role",
          path: "role",
          meta: {
            id: "admin_access--READ"
          },
          component: () => import("@/view/pages/role")
        },
        {
          path: "/setting",
          name: "setting",
          meta: {
            id: "setting--READ"
          },
          component: () => import("@/view/pages/setting")
        }
      ]
    },
    {
      path: "/custom-error",
      name: "error",
      component: () => import("@/view/pages/error/Error.vue"),
      children: [
        {
          path: "error-1",
          name: "error-1",
          component: () => import("@/view/pages/error/Error-1.vue")
        },
        {
          path: "error-2",
          name: "error-2",
          component: () => import("@/view/pages/error/Error-2.vue")
        },
        {
          path: "error-3",
          name: "error-3",
          component: () => import("@/view/pages/error/Error-3.vue")
        },
        {
          path: "error-4",
          name: "error-4",
          component: () => import("@/view/pages/error/Error-4.vue")
        },
        {
          path: "error-5",
          name: "error-5",
          component: () => import("@/view/pages/error/Error-5.vue")
        },
        {
          path: "error-6",
          name: "error-6",
          component: () => import("@/view/pages/error/Error-6.vue")
        }
      ]
    },
    {
      path: "/",
      component: () => import("@/view/pages/auth/login_pages/Login-1"),
      children: [
        {
          name: "login",
          path: "/login",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        },
        {
          name: "register",
          path: "/register",
          component: () => import("@/view/pages/auth/login_pages/Login-1")
        }
      ]
    },
    {
      path: "*",
      redirect: "/404"
    },
    {
      // the 404 route, when none of the above matches
      path: "/404",
      name: "404",
      component: () => import("@/view/pages/error/Error-1.vue")
    }
  ]
});

router.beforeEach((to, from, next) => {
  if (!to.meta.id || store.getters.getAccess.includes(to.meta.id)) {
    next();
  } else {
    next("/");
  }
});

export default router;
