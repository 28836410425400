import Vue from "vue";
import VueI18n from "vue-i18n";

// Localisation language list
import { locale as en } from "@/core/config/i18n/en";
import { locale as ch } from "@/core/config/i18n/ch";
import { locale as id } from "@/core/config/i18n/id";
import { locale as th } from "@/core/config/i18n/th";
import { locale as pt } from "@/core/config/i18n/pt";
import { locale as es } from "@/core/config/i18n/es";
import { locale as jp } from "@/core/config/i18n/jp";
import { locale as vn } from "@/core/config/i18n/vn";
import { locale as kr } from "@/core/config/i18n/kr";
import { locale as hi } from "@/core/config/i18n/hi";
import { locale as te } from "@/core/config/i18n/te";

Vue.use(VueI18n);

let messages = {};
messages = { ...messages, en, ch, id, th, pt, es, jp, vn, kr, hi, te };

// get current selected language
const lang = localStorage.getItem("language") || "en";

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  messages // set locale messages
});

export default i18n;
