// USA
export const locale = {
  HEADER: {
    HI: "है.",
    SIGN_OUT: "सिग्न आउट t"
  },
  MENU: {
    DASHBOARD: "डैशबोर्ड",
    TRANSACTION: "लेनदेन",
    RECENT_TRANSACTION: " हाल का लेनदेन",
    DEPOSIT: "जमा",
    WITHDRAWAL: "निकासी",
    AGENT_LIST: "एजेंट सूची",
    CLIENT_LIST: "ग्राहक सूची",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "ऑपरेटर सूची",
    CURRENCY_LIST: "करेंसी सूची",
    SETTING: "सेटिंग",
    ROLE: "रोले"
  },
  LOGIN: {
    USERNAME: "यूजरनाम",
    PASSWORD: "पासवर्ड",
    BUTTON: "सिग्न इन"
  },
  TABLE: {
    INDEX: " इंडेक्स",
    CLIENT_ID: "क्लाइंट आईडी",
    NAME: "नाम",
    USERNAME: "यूजरनाम",
    BALANCE: "बैलेंस",
    COMMISSION: "कमीशन",
    REQUEST: "निवेदन",
    STATUS: "स्थिति",
    FAILED: "अनुत्तीर्ण होना",
    SUCCESS: "सफलता",
    PENDING: "लंबित",
    REJECTED: "अस्वीकृत",
    PROCESSING: "प्रसंस्करण",
    AMOUNT: "राशि",
    RECEIVER_ADDRESS: "रिसीवर का पता",
    NETWORK: "नेटवर्क",
    TRX_ID: "ट्रांस आईडी",
    API_KEY: "एपीआई कुंजी",
    SECRET_KEY: "गुप्त कुंजी",
    SENDER_ADDRESS: "भेजने वाले का पता",
    TOKEN_TYPE: "टोकन प्रकार",
    TOKEN_NAME: "टोकन नाम",
    TYPE: "प्रकार",
    DATE_TIME: "दिनऔर समय",
    PER_PAGE: "प्रति पृष्ठ",
    ACTION: "एक्शन",
    READ: "पढ़ना",
    CREATE: "सृजन करना",
    UPDATE: "अपडेट",
    DELETE: "हटाएं",
    REMARKS: "टिप्पणियों",
    BLOCK_ID: "ब्लॉक आईडी",
    WALLET_ADDRESS: "वॉलेट का पता",
    RECEIVE_AMOUNT: "राशि प्राप्त करें",
    UPDATED_AT: "अद्यतन किया गया",
    CREATED_AT: " बनाया गया",
    ACCESS_KEY: "आगमन कुंजी",
    NO_DATA: "कोई डेटा नहीं",
    ACTIVE: "सक्रिय",
    BANNED: "प्रतिबंधित",
    INACTIVE: "निष्क्रिय",
    ENABLE: "सक्षम करना",
    DISABLE: "अक्षम करना",
    VIEW_ALL: "व्यू आल",
    COPY_ACCESS: "एक्सेस कॉपी करें",
    ENABLE_2FA: "2fa . सक्षम करें",
    COPY_CLIPBOARD: "क्लिपबोर्ड पर कॉपी किया गया है"
  },
  DASHBOARD: {
    AMOUNT_TRX: "लेन-देन की कुल दैनिक राशि",
    AMOUNT_WITHDRAW: "निकासी की कुल दैनिक राशि",
    NUMBER_TRX: "लेन-देन की कुल दैनिक संख्या",
    BALANCE: "क्रिमसनपे में कुल शेष राशि"
  },
  SETTING: {
    CHANGE_PASS: "पासवर्ड बदलें",
    UPDATE_GAS_FEE: "गैस शुल्क अपडेट करें",
    GAS_FEE: "गैस शुल्क",
    NEW_PASSWORD: "नया पासवर्ड",
    CONFIRM_PASSWORD: "नए पासवर्ड की पुष्टि करें",
    ENTER_NEW_PASSWORD: "नया पासवर्ड दर्ज करें",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "नेटवर्क शुल्क शामिल करें"
  },
  TRANSACTION: {
    TRANS_LIST: "लेन-देन सूची",
    START_UP_DATE: "प्रारंभ अपडेट हुवा तारीख",
    END_UP_DATE: "अंतिम अपडेट हुवा तारीख",
    SEARCH_FILTER: "खोज (trx आईडी, ब्लॉक आईडी, राशि)",
    SEARCH: "खोज कोई",
    NO_DATE: "तारीख नहीं चुनी गई",
    SENDER_ADDRESS: "भेजने वाले का पता",
    TOKEN_TYPE: "टोकन प्रकार",
    TOKEN_NAME: "टोकन नाम",
    STATUS: "स्थिति",
    ALL: "सब",
    PENDING: "लंबित",
    SUCCESS: "सफल",
    FAILED: "विफल",
    EXPORT: "निर्यात करें"
  },
  MODAL: {
    OK: "ठीक",
    CANCEL: "रद्द करें",
    CREATE_SUCESSFULL: "बनाना सफल रहा",
    UPDATE_SUCESSFULL: "अपडेट सफल हुवा",
    DELETE_CONFIRMATION: "क्या आप निश्चित हैं, हटाना चाहते हैं",
    DELETE_SUCESSFULL: "मिटाना सफल हुवा",
    FIELD_REQUIRED: "यह फ़ील्ड आवश्यक है",
    FIELD_REQUIRED_NUMBER:
      "यह फ़ील्ड आवश्यक है और एक मान्य धनात्मक संख्या होनी चाहिए",
    FIELD_REQUIRED_DECIMAL: "यह फ़ील्ड आवश्यक है और 0.1 . से अधिक होना चाहिए"
  },
  TIER: {
    CONFIRM_PASSWORD: "नए पासवर्ड की पुष्टि करें",
    ENTER_PASSWORD: "पास वर्ड दर्ज करें",
    ENTER_PERCENTAGE: "प्रतिशत दर्ज करें",
    ENTER_NAME: "नाम दर्ज करें",
    ENTER_USERNAME: "यूजरनाम नाम दर्ज करें",
    WILL_GENERATE: "स्वचालित रूप से उत्पन्न हो जाएगा",
    UPDATE: "अपडेट",
    CREATE: "सृजन करना",
    CLIENT: "ग्राहक",
    OPERATOR: "ऑपरेटर"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "राशी दर्ज करें",
    ENTER_RECEIVER: "रिसीवर का पता दर्ज करें",
    CURRENCY: "करेंसी",
    WITHDRAWAL_FEE: "निकासी शुल्क",
    REQ_NEW_WITHDRAW: "नई निकासी का अनुरोध करें",
    UPDATE_STATUS: "अपडेट की स्थिति",
    TRX_ID: "ट्रांस आईडी",
    ENTER_TRX_ID: " लेन-देन आईडी दर्ज करें",
    START_UP_DATE: "प्रारंभ अपडेट हुवा तारीख",
    END_UP_DATE: "अंतिम अपडेट हुवा तारीख",
    SEARCH: "खोज कोई",
    NO_DATE: "तारीख नहीं चुनी गई",
    RECEIVER_ADDRESS: "रिसीवर का पता",
    TRANS_ID: "ट्रांस आईडी",
    NETWORK: "नेटवर्क",
    STATUS: "स्थिति",
    ALL: "सब",
    PENDING: "लंबित",
    SUCCESS: "सफल",
    FAILED: "विफल",
    REJECTED: "अस्वीकृत",
    PROCESSING: "प्रसंस्करण",
    EXPORT: "निर्यात",
    AMOUNT_VALIDATION: "राशि निकासी शुल्क से बड़ी होनी चाहिए"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "कृपया स्कैन करें या अपने Google ऑथेंटिकटोर का कोड दर्ज करें:",
    UNABLE_SCAN:
      "यदि आप QR कोड को स्कैन करने में असमर्थ हैं, तो कृपया इस कोड को app में मैन्युअल रूप से दर्ज करें।",
    SAVE_KEY: "इस बैकअप कुंजी को सुरक्षित स्थान पर सहेजें करे",
    RECOVER:
      "यदि आप अपना फोन खो देते हैं तो यह कुंजी आपको अपना ऑथेंटिकटोर पुनर्प्राप्त करने की अनुमति देगी। अन्यथा, Google ऑथेंटिकटोर को रीसेट करने में कम से कम 7 दिन लगेंगे। ",
    VERIFY_ACCOUNT: "अपने खाते को वेरीफाई करें",
    ENTER_DIGIT: "Google ऑथेंटिकटोर से 6-अंकीय कोड दर्ज करें",
    VALIDATION_VERIFICATION_CODE:
      "वेरिफिकेशन कोड 6 अंकों की संख्या का होना चाहिए",
    INVALID_CODE: "अमान्य Google ऑथेंटिकटोर कोड, कृपया पुनः प्रयास करें",
    VALIDATOR_ENABLED: "2FA सफलतापूर्वक सक्षम कर दिया गया है!",
    VALIDATOR_DISABLED: "2FA सफलतापूर्वक अक्षम कर दिया गया है!",
    VALIDATION_CONFIRM_PASSWORD: "दर्ज किया पासवर्ड, पासवर्ड से मेल नहीं"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "क्या आप निश्चित हैं, अक्षम करना चाहते हैं",
    CONFIRMATION_ENABLE: "क्या आप निश्चित हैं, सक्षम करना चाहते हैं"
  },
  ROLE: {
    ACCESS: "प्रवेश"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "यूजरनाम नहीं मिला",
    SERVER_ERROR: "सर्वर में कुछ गड़बड़ है",
    DATA_NOT_FOUND: "डेटा नहीं मिला",
    INSUFFICENT_BALANCE: "अपर्याप्त शेष राशि",
    CLIENT_NOT_FOUND: "क्लाइंट डेटा नहीं मिला",
    USER_PASS_INCORRECT: "यूजरनाम या पासवर्ड गलत है",
    USERNAME_EXIST: "यूजरनाम पहले से मौजूद है, कृपया दूसरा प्रयास करें"
  }
};
