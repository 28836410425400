import ApiService from "@/core/services/api.service";
import JwtService from "@/core/services/jwt.service";

// action types
export const VERIFY_AUTH = "verifyAuth";
export const SET_LOGIN = "setLogin";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_PASSWORD = "updateUser";
export const UPDATE_2FA = "update2FA";

// mutation types
export const PURGE_AUTH = "logOut";
export const SET_AUTH = "setUser";
export const SET_2FA = "set2FA";
export const SET_PASSWORD = "setPassword";
export const SET_ERROR = "setError";
export const SET_TIER = "setTier";

const state = {
  errors: null,
  user: {},
  tier: [],
  isAuthenticated: !!JwtService.getToken(),
  access: []
};

const getters = {
  currentUser(state) {
    return state.user;
  },
  isAuthenticated(state) {
    return state.isAuthenticated;
  },
  currentTier(state) {
    return state.tier.find(function(e) {
      return state.user.type == e.id;
    });
  },
  downlineTier(state, getters) {
    if (!getters.currentTier) {
      return false;
    }

    return state.tier.find(function(e) {
      return getters.currentTier.tier + 1 == e.tier;
    });
  },
  getAccess(state) {
    return state.access;
  },
  googleAuth(state) {
    return state.user.g_auth;
  }
};

const actions = {
  [LOGIN](context, credentials) {
    return new Promise((resolve, reject) => {
      ApiService.post("/auth/login", credentials)
        .then(({ data }) => {
          context.commit(SET_TIER, [
            { id: "ADMIN", name: "Admin", tier: 1 },
            { id: "AGENT", name: "Agent", tier: 2 },
            { id: "CLIENT", name: "Client", tier: 3 }
          ]);
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [SET_LOGIN](context, data) {
    context.commit(SET_AUTH, data);
  },
  [LOGOUT](context) {
    context.commit(PURGE_AUTH);
  },
  [REGISTER](context, credentials) {
    return new Promise(resolve => {
      ApiService.post("login", credentials)
        .then(({ data }) => {
          context.commit(SET_AUTH, data);
          resolve(data);
        })
        .catch(({ response }) => {
          context.commit(SET_ERROR, response.data.errors);
        });
    });
  },
  [VERIFY_AUTH](context) {
    if (JwtService.getToken()) {
      ApiService.setHeader();
    } else {
      context.commit(PURGE_AUTH);
    }
  },
  [UPDATE_PASSWORD](context, payload) {
    const password = payload;

    return ApiService.put("password", password).then(({ data }) => {
      context.commit(SET_PASSWORD, data);
      return data;
    });
  },
  [UPDATE_2FA](context, payload) {
    context.commit(SET_2FA, payload);
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  },
  [SET_AUTH](state, user) {
    state.isAuthenticated = true;
    state.user = user;
    state.errors = {};
    JwtService.saveToken(state.user.token);

    let access = [];
    user.permission.forEach(e => {
      e.access.forEach(o => {
        access.push(`${e.resource}--${o}`);
      });
    });
    state.access = access;
  },
  [SET_PASSWORD](state, password) {
    state.user.password = password;
  },
  [PURGE_AUTH](state) {
    state.isAuthenticated = false;
    state.user = {};
    state.errors = {};
    JwtService.destroyToken();
  },
  [SET_TIER](state, data) {
    state.tier = data;
  },
  [SET_2FA](state, data) {
    state.user.g_auth = data;
  }
};
export default {
  state,
  actions,
  mutations,
  getters
};
