import Vue from "vue";
import Vuex from "vuex";

import dashboard from "./dashboard.module";
import auth from "./auth.module";
import transaction from "./transaction.module";
import deposit from "./deposit.module";
import withdrawal from "./withdrawal.module";
import setting from "./setting.module";
import htmlClass from "./htmlclass.module";
import config from "./config.module";
import breadcrumbs from "./breadcrumbs.module";
import profile from "./profile.module";
import client from "./client.module";
import operator from "./operator.module";
import gasFee from "./gas-fee.module";
import currency from "./currency.module";
import role from "./role.module";
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [createPersistedState()],
  modules: {
    dashboard,
    auth,
    transaction,
    deposit,
    withdrawal,
    setting,
    htmlClass,
    config,
    breadcrumbs,
    profile,
    client,
    operator,
    gasFee,
    currency,
    role
  }
});
