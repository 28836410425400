import CurrencyService from "@/core/services/api.service";

// action types
export const GET_CURRENCY = "getCurrency";
export const UPDATE_CURRENCY = "updateCurrency";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  currency: {}
};

const getters = {};

const actions = {
  [GET_CURRENCY](context, data) {
    return new Promise((resolve, reject) => {
      CurrencyService.query("/networks/token", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_CURRENCY](context, payload) {
    return new Promise((resolve, reject) => {
      CurrencyService.post("/setting/token/", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
