// USA
export const locale = {
  HEADER: {
    HI: "Oi,",
    SIGN_OUT: "Sair"
  },
  MENU: {
    DASHBOARD: "Painel",
    TRANSACTION: "Transação",
    RECENT_TRANSACTION: "Transação recente",
    DEPOSIT: "Depósito",
    WITHDRAWAL: "Saque",
    AGENT_LIST: "Lista de agentes.",
    CLIENT_LIST: "Lista de clientes",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "Lista de operadores.",
    CURRENCY_LIST: "Lista de moedas",
    SETTING: "Configuração",
    ROLE: "Função"
  },
  LOGIN: {
    USERNAME: "Usuário",
    PASSWORD: "Senha",
    BUTTON: "Entrar"
  },
  TABLE: {
    INDEX: "Índice",
    CLIENT_ID: "ID do Cliente",
    NAME: "Nome",
    USERNAME: "Usuário",
    BALANCE: "Saldo",
    COMMISSION: "Comissão",
    REQUEST: "Solicitação",
    STATUS: "Status",
    FAILED: "REPROVADO",
    SUCCESS: "SUCESSO",
    PENDING: "PENDENTE",
    REJECTED: "REJEITADO",
    PROCESSING: "EM PROCESSAMENTO",
    AMOUNT: "Montante",
    RECEIVER_ADDRESS: "Endereço do destinatário",
    NETWORK: "Rede",
    TRX_ID: "ID trans",
    API_KEY: "Chave API",
    SECRET_KEY: "Chave secreta",
    SENDER_ADDRESS: "Endereço do remetente",
    TOKEN_TYPE: "Tipo de token",
    TOKEN_NAME: "Nome do token",
    TYPE: "Tipo",
    DATE_TIME: "Data hora",
    PER_PAGE: "Por página",
    ACTION: "Ação",
    READ: "Ler",
    CREATE: "Criar",
    UPDATE: "Atualizar",
    DELETE: "Excluir",
    REMARKS: "Observações.",
    BLOCK_ID: "Bloqueio de ID",
    WALLET_ADDRESS: "Endereço da carteira",
    RECEIVE_AMOUNT: "Valor a recber",
    UPDATED_AT: "Atualizado em",
    CREATED_AT: "Criado em",
    ACCESS_KEY: "Chave de acesso",
    NO_DATA: "Sem dados",
    ACTIVE: "ATIVO",
    BANNED: "BANIDO",
    INACTIVE: "Inativo",
    ENABLE: "Habilitar",
    DISABLE: "Desativar",
    VIEW_ALL: "Ver tudo",
    COPY_ACCESS: "Copie o acesso",
    ENABLE_2FA: "Ativar 2fa.",
    COPY_CLIPBOARD: "Foi copiado para a área de transferência"
  },
  DASHBOARD: {
    AMOUNT_TRX: "Quantidade total diária de transações",
    AMOUNT_WITHDRAW: "Quantidade total diária de saques",
    NUMBER_TRX: "Número diário total de transações",
    BALANCE: "Saldo em Crimsonpay"
  },
  SETTING: {
    CHANGE_PASS: "Mudar senha",
    UPDATE_GAS_FEE: "Taxa de atualização do Gas",
    GAS_FEE: "Taxa de Gas",
    NEW_PASSWORD: "Nova Senha",
    CONFIRM_PASSWORD: "Confirme a nova senha",
    ENTER_NEW_PASSWORD: "Insira a nova senha",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "Inclua taxa de rede"
  },
  TRANSACTION: {
    TRANS_LIST: "Lista de transações.",
    START_UP_DATE: "Iniciar data atualizada",
    END_UP_DATE: "Data atualizada final",
    SEARCH_FILTER: "Pesquisar (ID TRX, Block ID, valor)",
    SEARCH: "Pesquisar",
    NO_DATE: "Nenhum dado selecionado",
    SENDER_ADDRESS: "Endereço do remetente",
    TOKEN_TYPE: "Tipo de token",
    TOKEN_NAME: "Nome do token",
    STATUS: "Status",
    ALL: "TODO",
    PENDING: "PENDENTE",
    SUCCESS: "SUCESSO",
    FAILED: "REPROVADO",
    EXPORT: "Exportar"
  },
  MODAL: {
    OK: "OK",
    CANCEL: "Cancelar",
    CREATE_SUCESSFULL: "A criação foi bem sucedida",
    UPDATE_SUCESSFULL: "A atualização foi bem sucedida",
    DELETE_CONFIRMATION: "Tem certeza, quer excluir",
    DELETE_SUCESSFULL: "A exclusão foi bem sucedida",
    FIELD_REQUIRED: "Este campo é obrigatório",
    FIELD_REQUIRED_NUMBER:
      "Este campo é necessário e deve ser um número positivo válido",
    FIELD_REQUIRED_DECIMAL: "Este campo é necessário e deve ser maior que 0,1"
  },
  TIER: {
    CONFIRM_PASSWORD: "Confirme a nova senha",
    ENTER_PASSWORD: "Insira a senha",
    ENTER_PERCENTAGE: "Insira porcentagem.",
    ENTER_NAME: "Insira o nome",
    ENTER_USERNAME: "Insira o usuário",
    WILL_GENERATE: "Será gerado automaticamente",
    UPDATE: "Atualizar",
    CREATE: "Criar",
    CLIENT: "Cliente",
    OPERATOR: "Operador"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "Insira a quantidade",
    ENTER_RECEIVER: "Digite o endereço do destinatário",
    CURRENCY: "Moeda",
    WITHDRAWAL_FEE: "Taxa de retirada",
    REQ_NEW_WITHDRAW: "Solicitar nova retirada",
    UPDATE_STATUS: "Atualizar o status",
    TRX_ID: "ID trans",
    ENTER_TRX_ID: "Digite o ID da transação",
    START_UP_DATE: "Data de início",
    END_UP_DATE: "Data de Atualização Final",
    SEARCH: "Procurar",
    NO_DATE: "Nenhum dado selecionado",
    RECEIVER_ADDRESS: "Endereço do destinatário",
    TRANS_ID: "ID trans",
    NETWORK: "Rede",
    STATUS: "Status",
    ALL: "TODO",
    PENDING: "PENDENTE",
    SUCCESS: "SUCESSO",
    FAILED: "REPROVADO",
    REJECTED: "Rejeitado",
    PROCESSING: "EM PROCESSAMENTO",
    EXPORT: "Exportar",
    AMOUNT_VALIDATION: "O valor deve ser maior da taxa de retirada"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN:
      "Por favor, digitalize ou digite código no seu Google autenticador:",
    UNABLE_SCAN:
      "Se você não conseguir escaniar o QR code, insira este código manualmente no aplicativo.",
    SAVE_KEY: "Salvar esta chave de backup em um local seguro",
    RECOVER:
      "Essa chave permitirá que você recupere seu Autenticador se você perder o telefone. Caso contrário, redefinir o Google Autenticador levará pelo menos 7 dias.",
    VERIFY_ACCOUNT: "Verifique sua conta",
    ENTER_DIGIT: "Digite o código de 6 dígitos do Google Authenticator",
    VALIDATION_VERIFICATION_CODE:
      "O código de verificação deve ser um número de 6 dígitos",
    INVALID_CODE:
      "Código inválido do Google Authenticator, por favor, tente novamente",
    VALIDATOR_ENABLED: "2fa foi ativado com sucesso!",
    VALIDATOR_DISABLED: "2fa foi desativado com sucesso!",
    VALIDATION_CONFIRM_PASSWORD: "A senha confirmada não corresponde à senha"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "Tem certeza, quer desativar",
    CONFIRMATION_ENABLE: "Tem certeza, quer ativar"
  },
  ROLE: {
    ACCESS: "Acessar"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "Nome de usuário não encontrado",
    SERVER_ERROR: "Algo errado com o servidor",
    DATA_NOT_FOUND: "Dados não encontrados",
    INSUFFICENT_BALANCE: "Saldo Insuficiente",
    CLIENT_NOT_FOUND: "Dados do cliente não encontrados",
    USER_PASS_INCORRECT: "O nome de usuário ou senha está incorreto",
    USERNAME_EXIST: "Nome de usuário já existe, por favor, tente outro"
  }
};
