import ApiService from "@/core/services/api.service";

// action types
export const GET_TRANSACTION = "getTransaction";
export const EXPORT_DATA = "exportTransaction";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  transaction: {}
};

const getters = {};

const actions = {
  [GET_TRANSACTION](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/recent-transaction/admin", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [EXPORT_DATA](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/recent-transaction/admin/export", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
