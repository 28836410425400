// USA
export const locale = {
  HEADER: {
    HI: "안녕하세요,",
    SIGN_OUT: "로그아웃"
  },
  MENU: {
    DASHBOARD: "대시보드",
    TRANSACTION: "트랜잭션",
    RECENT_TRANSACTION: "최근 트랜잭션",
    DEPOSIT: "입금",
    WITHDRAWAL: "출금",
    AGENT_LIST: "에이전트 목록",
    CLIENT_LIST: "클라이언트 목록",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "오퍼레이터 목록",
    CURRENCY_LIST: "통화 목록",
    SETTING: "설정",
    ROLE: "역할"
  },
  LOGIN: {
    USERNAME: "사용자 이름",
    PASSWORD: "비밀번호",
    BUTTON: "로그인"
  },
  TABLE: {
    INDEX: "인덱스",
    CLIENT_ID: "클라이언트 Id",
    NAME: "이름",
    USERNAME: "사용자 이름",
    BALANCE: "잔액",
    COMMISSION: "커미션",
    REQUEST: "요청",
    STATUS: "상태",
    FAILED: "실패",
    SUCCESS: "성공",
    PENDING: "보류 중",
    REJECTED: "거절",
    PROCESSING: "처리 중",
    AMOUNT: "금액",
    RECEIVER_ADDRESS: "수신자 주소",
    NETWORK: "네트워크",
    TRX_ID: "트랜잭션 ID",
    API_KEY: "API 키",
    SECRET_KEY: "시크릿 키",
    SENDER_ADDRESS: "발신자 주소",
    TOKEN_TYPE: "토큰 타입",
    TOKEN_NAME: "토큰 명",
    TYPE: "타입",
    DATE_TIME: "날짜 시간",
    PER_PAGE: "페이지 당",
    ACTION: "액션",
    READ: "읽기",
    CREATE: "생성",
    UPDATE: "업데이트",
    DELETE: "삭제",
    REMARKS: "비고",
    BLOCK_ID: "Block ID",
    WALLET_ADDRESS: "지갑 주소",
    RECEIVE_AMOUNT: "수령 금액",
    UPDATED_AT: "업데이트 시간",
    CREATED_AT: "생성 날짜",
    ACCESS_KEY: "액세스 키",
    NO_DATA: "데이터 없음",
    ACTIVE: "활성화",
    BANNED: "금지",
    INACTIVE: "비활성",
    ENABLE: "활성화",
    DISABLE: "비활성화",
    VIEW_ALL: "전체 보기",
    COPY_ACCESS: "복사 권한",
    ENABLE_2FA: "2fa 활성화",
    COPY_CLIPBOARD: "클립보드에 복사되었습니다."
  },
  DASHBOARD: {
    AMOUNT_TRX: "일일 총 트랜잭션 금액",
    AMOUNT_WITHDRAW: "일일 총 출금 금액",
    NUMBER_TRX: "일일 총 트랜잭션 횟수",
    BALANCE: "CrimsonPay의 총 잔액"
  },
  SETTING: {
    CHANGE_PASS: "비밀번호 변경",
    UPDATE_GAS_FEE: "Gas 수수료 업데이트",
    GAS_FEE: "Gas 수수료",
    NEW_PASSWORD: "새 비밀번호",
    CONFIRM_PASSWORD: "새 비밀번호 확인",
    ENTER_NEW_PASSWORD: "새 비밀번호 입력",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "네트워크 수수료 포함"
  },
  TRANSACTION: {
    TRANS_LIST: "트랜잭션 목록",
    START_UP_DATE: "업데이트된 날짜 시작",
    END_UP_DATE: "업데이트된 날짜 종료",
    SEARCH_FILTER: "검색 (trx id, block id, 금액)",
    SEARCH: "검색",
    NO_DATE: "선택한 날짜가 없습니다.",
    SENDER_ADDRESS: "발신자 주소",
    TOKEN_TYPE: "토큰 타입",
    TOKEN_NAME: "토큰 명",
    STATUS: "상태",
    ALL: "전부",
    PENDING: "보류 중",
    SUCCESS: "성공",
    FAILED: "실패",
    EXPORT: "내보내기"
  },
  MODAL: {
    OK: "확인",
    CANCEL: "취소",
    CREATE_SUCESSFULL: "생성 성공",
    UPDATE_SUCESSFULL: "업데이트 성공",
    DELETE_CONFIRMATION: "정말로 삭제하시겠습니까?",
    DELETE_SUCESSFULL: "삭제 성공",
    FIELD_REQUIRED: "이 필드는 필수입니다.",
    FIELD_REQUIRED_NUMBER: "이 필드는 필수이며 유효한 양수여야 합니다.",
    FIELD_REQUIRED_DECIMAL: "이 필드는 필수이며 0.1보다 커야 합니다."
  },
  TIER: {
    CONFIRM_PASSWORD: "새 비밀번호 확인",
    ENTER_PASSWORD: "비밀번호 입력",
    ENTER_PERCENTAGE: "퍼센티지 입력",
    ENTER_NAME: "이름 입력",
    ENTER_USERNAME: "사용자 이름 입력",
    WILL_GENERATE: "자동으로 생성됩니다.",
    UPDATE: "업데이트",
    CREATE: "생성",
    CLIENT: "클라이언트",
    OPERATOR: "오퍼레이터"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "금액 입력",
    ENTER_RECEIVER: "수신자 주소 입력",
    CURRENCY: "통화",
    WITHDRAWAL_FEE: "출금 수수료",
    REQ_NEW_WITHDRAW: "새 출금 요청",
    UPDATE_STATUS: "상태 업데이트",
    TRX_ID: "트랜잭션 ID",
    ENTER_TRX_ID: "트랜잭션 ID 입력",
    START_UP_DATE: "업데이트된 날짜 시작",
    END_UP_DATE: "업데이트된 날짜 종료",
    SEARCH: "검색",
    NO_DATE: "선택한 날짜가 없습니다.",
    RECEIVER_ADDRESS: "수신자 주소",
    TRANS_ID: "트랜잭션 ID",
    NETWORK: "네트워크",
    STATUS: "상태",
    ALL: "전부",
    PENDING: "보류 중",
    SUCCESS: "성공",
    FAILED: "실패",
    REJECTED: "거절",
    PROCESSING: "처리 중",
    EXPORT: "내보내기",
    AMOUNT_VALIDATION: "출금 수수료보다 금액이 커야 합니다."
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "구글 OTP에서 코드를 스캔하거나 입력하십시오:",
    UNABLE_SCAN:
      "QR 코드를 스캔할 수 없는 경우 이 코드를 앱에 수동으로 입력하십시오.",
    SAVE_KEY: "이 백업 키를 안전한 위치에 저장하십시오.",
    RECOVER:
      "이 키를 사용하면 휴대폰을 분실한 경우 OTP를 복구할 수 있습니다. 그렇지 않으면 구글 OTP를 재설정하는 데 최소 7일이 소요됩니다.",
    VERIFY_ACCOUNT: "계정 확인",
    ENTER_DIGIT: "구글 OTP의 6자리 코드를 입력하십시오.",
    VALIDATION_VERIFICATION_CODE: "인증 코드는 6자리 숫자여야 합니다.",
    INVALID_CODE: "잘못된 구글 OPT 코드입니다. 다시 시도하십시오.",
    VALIDATOR_ENABLED: "2FA가 성공적으로 활성화되었습니다!",
    VALIDATOR_DISABLED: "2FA가 성공적으로 비활성화되었습니다!",
    VALIDATION_CONFIRM_PASSWORD: "확인 비밀번호가 비밀번호와 일치하지 않습니다."
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "정말로 비활성화하시겠습니까?",
    CONFIRMATION_ENABLE: "정말로 비활성화하시겠습니까?"
  },
  ROLE: {
    ACCESS: "액세스"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "사용자 이름을 찾을 수 없습니다",
    SERVER_ERROR: "서버에 문제가 있습니다",
    DATA_NOT_FOUND: "데이터를 찾을 수 없습니다",
    INSUFFICENT_BALANCE: "잔액이 부족합니다",
    CLIENT_NOT_FOUND: "클라이언트 데이터를 찾을 수 없습니다",
    USER_PASS_INCORRECT: "사용자 이름 또는 비밀번호가 잘못되었습니다",
    USERNAME_EXIST:
      "사용자 이름이 이미 존재합니다, 다른 사용자 이름을 시도하십시오."
  }
};
