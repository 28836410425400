import ApiService from "@/core/services/api.service";

// action types
export const GET_WITHDRAWAL = "getWithdrawal";
export const CREATE_WITHDRAWAL = "createWithdrawal";
export const UPDATE_STATUS = "updateStatus";
export const GET_FEE = "getFee";
export const EXPORT_DATA = "exportWithdrawal";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  deposit: {}
};

const getters = {};

const actions = {
  [GET_WITHDRAWAL](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/withdrawal/admin", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [CREATE_WITHDRAWAL](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.post("/withdrawal/admin", payload)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_STATUS](context, payload) {
    return new Promise((resolve, reject) => {
      ApiService.put("/withdrawal/admin/" + payload.code, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [GET_FEE](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/withdrawal/admin/fee")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [EXPORT_DATA](context, data) {
    return new Promise((resolve, reject) => {
      ApiService.query("/withdrawal/admin/export", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
