import ClientService from "@/core/services/api.service";

// action types
export const GET_CLIENT = "getClient";
export const CREATE_CLIENT = "createClient";
export const UPDATE_CLIENT = "updateClient";
export const DELETE_CLIENT = "deleteClient";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null,
  deposit: {}
};

const getters = {};

const actions = {
  [GET_CLIENT](context, data) {
    return new Promise((resolve, reject) => {
      ClientService.query("/client/admin", { ...data })
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [CREATE_CLIENT](context, payload) {
    return new Promise((resolve, reject) => {
      ClientService.post("/client/admin", payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch((err) => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [UPDATE_CLIENT](context, payload) {
    return new Promise((resolve, reject) => {
      ClientService.put("/client/admin/" + payload.username, payload.data)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [DELETE_CLIENT](context, payload) {
    return new Promise((resolve, reject) => {
      ClientService.delete("/client/admin/" + payload.username)
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
