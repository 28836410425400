import ApiService from "@/core/services/api.service";

// action types
export const GET_STATISTIC = "getStatistic";
export const GET_STAT_LIST = "getStatList";
export const EXPORT_DATA = "exportStatList";

// mutation types
export const SET_ERROR = "setError";

const state = {
  errors: null
};

const getters = {};

const actions = {
  [GET_STATISTIC](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/dashboard/admin/box")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [GET_STAT_LIST](context) {
    return new Promise((resolve, reject) => {
      ApiService.get("/dashboard/admin/table")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  },
  [EXPORT_DATA](context) {
    return new Promise((resolve, reject) => {
      ApiService.query("/dashboard/admin/table/export")
        .then(({ data }) => {
          resolve(data);
        })
        .catch(err => {
          context.commit(SET_ERROR, err.message);
          reject(err);
        });
    });
  }
};

const mutations = {
  [SET_ERROR](state, error) {
    state.errors = error;
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
