// USA
export const locale = {
  HEADER: {
    HI: "สวัสดี,",
    SIGN_OUT: "ออกจากระบบ"
  },
  MENU: {
    DASHBOARD: "หน้าแดชบอร์ด",
    TRANSACTION: "ธุรกรรม",
    RECENT_TRANSACTION: "ธุรกรรมล่าสุด",
    DEPOSIT: "ฝาก",
    WITHDRAWAL: "ถอน",
    AGENT_LIST: "รายชื่อตัวแทน",
    CLIENT_LIST: "รายชื่อลูกค้า",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "รายชื่อผู้ประกอบการ",
    CURRENCY_LIST: "รายการสกุลเงิน",
    SETTING: "การตั้งค่า",
    ROLE: "หน้าที่"
  },
  LOGIN: {
    USERNAME: "ยูสเซอร์เนม",
    PASSWORD: "รหัสผ่าน",
    BUTTON: "เข้าสู่ระบบ"
  },
  TABLE: {
    INDEX: "ดัชนี",
    CLIENT_ID: "รหัสลูกค้า",
    NAME: "ชื่อ",
    USERNAME: "ยูสเซอร์เนม",
    BALANCE: "คงเหลือ",
    COMMISSION: "คอมมิชชั่น",
    REQUEST: "ร้องขอ",
    STATUS: "สถานะ",
    FAILED: "ไม่สำเร็จ",
    SUCCESS: "สำเร็จ",
    PENDING: "รอดำเนินการ",
    REJECTED: "ถูกปฏิเสธ",
    PROCESSING: "กำลังดำเนินการ",
    AMOUNT: "จำนวน",
    RECEIVER_ADDRESS: "ที่อยู่ผู้รับ",
    NETWORK: "เครือข่าย",
    TRX_ID: "ธุรกรรม ID",
    API_KEY: "คีย์ API",
    SECRET_KEY: "รหัสลับ",
    SENDER_ADDRESS: "ที่อยู่ผู้ส่ง",
    TOKEN_TYPE: "ประเภทโทเค็น",
    TOKEN_NAME: "ชื่อโทเค็น",
    TYPE: "ประเภท",
    DATE_TIME: "วันเวลา",
    PER_PAGE: "ต่อหน้า",
    ACTION: "ดำเนินการ",
    READ: "อ่าน",
    CREATE: "สร้าง",
    UPDATE: "อัปเดต",
    DELETE: "ลบ",
    REMARKS: "หมายเหตุ",
    BLOCK_ID: "บล็อคไอดี",
    WALLET_ADDRESS: "ที่อยู่กระเป๋าเงิน",
    RECEIVE_AMOUNT: "รับจำนวน",
    UPDATED_AT: "อัปเดต At",
    CREATED_AT: "สร้าง At",
    ACCESS_KEY: "คีย์การเข้าถึง",
    NO_DATA: "ไม่มีข้อมูล",
    ACTIVE: "ใช้งานอยู่",
    BANNED: "ห้าม",
    INACTIVE: "ไม่ใช้งาน",
    ENABLE: "เปิดใช้งาน",
    DISABLE: "ปิดการใช้งาน",
    VIEW_ALL: "ดูทั้งหมด",
    COPY_ACCESS: "คัดลอกการเข้าถึง",
    ENABLE_2FA: "เปิดใช้งาน2fa",
    COPY_CLIPBOARD: "คัดลอกไปยังคลิปบอร์ดแล้ว"
  },
  DASHBOARD: {
    AMOUNT_TRX: "ยอดรวมของการทำธุรกรรมรายวัน",
    AMOUNT_WITHDRAW: "ยอดถอนรวมรายวัน",
    NUMBER_TRX: "จำนวนธุรกรรมรายวันทั้งหมด",
    BALANCE: "ยอดเงินคงเหลือใน CrimsonPay"
  },
  SETTING: {
    CHANGE_PASS: "เปลี่ยนรหัสผ่าน",
    UPDATE_GAS_FEE: "อัพเดทค่าธรรมเนียม",
    GAS_FEE: "ค่าธรรมเนียม",
    NEW_PASSWORD: "รหัสผ่านใหม่",
    CONFIRM_PASSWORD: "ยืนยันรหัสผ่านใหม่",
    ENTER_NEW_PASSWORD: "ใส่รหัสผ่านใหม่",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "รวมค่าธรรมเนียมเครือข่าย"
  },
  TRANSACTION: {
    TRANS_LIST: "รายการธุรกรรม",
    START_UP_DATE: "เริ่มวันที่อัปเดต",
    END_UP_DATE: "สิ้นสุดวันที่อัปเดต",
    SEARCH_FILTER: "ค้นหา (trx id, บล็อค id, จำนวน)",
    SEARCH: "ค้นหา",
    NO_DATE: "ไม่ได้เลือกวันที่",
    SENDER_ADDRESS: "ที่อยู่ผู้ส่ง",
    TOKEN_TYPE: "ประเภทโทเค็น",
    TOKEN_NAME: "ชื่อโทเค็น",
    STATUS: "สถานะ",
    ALL: "ทั้งหมด",
    PENDING: "รอดำเนินการ",
    SUCCESS: "สำเร็จ",
    FAILED: "ไม่สำเร็จ",
    EXPORT: "ส่งออก"
  },
  MODAL: {
    OK: "ตกลง",
    CANCEL: "ยกเลิก",
    CREATE_SUCESSFULL: "สร้างสำเร็จ",
    UPDATE_SUCESSFULL: "อัพเดทสำเร็จ",
    DELETE_CONFIRMATION: "แน่ใจหรือว่าต้องการลบ",
    DELETE_SUCESSFULL: "ลบสำเร็จ",
    FIELD_REQUIRED: "ช่องนี้จำเป็นต้องระบุ",
    FIELD_REQUIRED_NUMBER: "ช่องนี้จำเป็นและต้องเป็นจำนวนบวกที่ถูกต้อง",
    FIELD_REQUIRED_DECIMAL: "ช่องนี้จำเป็นและต้องมากกว่า 0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "ยืนยันรหัสผ่านใหม่",
    ENTER_PASSWORD: "กรอกรหัสผ่าน",
    ENTER_PERCENTAGE: "กรอกเปอร์เซ็นต์",
    ENTER_NAME: "ใส่ชื่อ",
    ENTER_USERNAME: "ใส่ยูสเซอร์เนม",
    WILL_GENERATE: "จะถูกสร้างขึ้นโดยอัตโนมัติ",
    UPDATE: "อัปเดต",
    CREATE: "สร้าง",
    CLIENT: "ลูกค้า",
    OPERATOR: "โอเปอเรเตอร์"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "ใส่จำนวนเงิน",
    ENTER_RECEIVER: "กรอกที่อยู่ผู้รับ",
    CURRENCY: "สกุลเงิน",
    WITHDRAWAL_FEE: "ค่าธรรมเนียมถอน",
    REQ_NEW_WITHDRAW: "ขอถอนใหม่",
    UPDATE_STATUS: "อัพเดทสถานะ",
    TRX_ID: "ธุรกรรม ID",
    ENTER_TRX_ID: "กรอกธุรกรรม ID",
    START_UP_DATE: "เริ่มวันที่อัปเดต",
    END_UP_DATE: "สิ้นสุดวันที่อัปเดต",
    SEARCH: "ค้นหา",
    NO_DATE: "ไม่ได้เลือกวันที่",
    RECEIVER_ADDRESS: "ที่อยู่ผู้รับ",
    TRANS_ID: "ธุรกรรม ID",
    NETWORK: "เครือข่าย",
    STATUS: "สถานะ",
    ALL: "ทั้งหมด",
    PENDING: "รอดำเนินการ",
    SUCCESS: "สำเร็จ",
    FAILED: "ไม่สำเร็จ",
    REJECTED: "ถูกปฏิเสธ",
    PROCESSING: "กำลังดำเนินการ",
    EXPORT: "ส่งออก",
    AMOUNT_VALIDATION: "จำนวนเงินจะต้องมากขึ้นจากค่าธรรมเนียมถอน"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "โปรดสแกนหรือกรอกรหัสใน Google Authenticator ของคุณ:",
    UNABLE_SCAN: "หากคุณไม่สามารถสแกนรหัส QR ได้ โปรดกรอกรหัสนี้ด้วยตนเองในแอป",
    SAVE_KEY: "บันทึกรหัสสำรองนี้ไว้ในตำแหน่งที่ปลอดภัย",
    RECOVER:
      "รหัสนี้จะช่วยให้คุณสามารถกู้คืน Google Authenticator ของคุณได้หากคุณทำโทรศัพท์หาย มิเช่นนั้น การรีเซ็ต Google Authenticator จะใช้เวลาอย่างน้อย 7 วัน",
    VERIFY_ACCOUNT: "ตรวจสอบบัญชีของคุณ",
    ENTER_DIGIT: "กรอกรหัส 6 หลักจาก Google Authenticator",
    VALIDATION_VERIFICATION_CODE: "รหัสยืนยันต้องเป็นตัวเลข 6 หลัก",
    INVALID_CODE: "รหัส Google Authenticator ไม่ถูกต้อง โปรดลองอีกครั้ง",
    VALIDATOR_ENABLED: "2FA เปิดใช้งานสำเร็จแล้ว!",
    VALIDATOR_DISABLED: "2FA ปิดการใช้งานเรียบร้อยแล้ว!",
    VALIDATION_CONFIRM_PASSWORD: "ยืนยันรหัสผ่านไม่ตรงกับรหัสผ่าน"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "คุณแน่ใจหรือว่าต้องการปิดการใช้งาน",
    CONFIRMATION_ENABLE: "คุณแน่ใจหรือว่าต้องการเปิดใช้งาน"
  },
  ROLE: {
    ACCESS: "เข้าถึง"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "ไม่พบยูสเซอร์เนม",
    SERVER_ERROR: "มีบางอย่างผิดปกติกับเซิร์ฟเวอร์",
    DATA_NOT_FOUND: "ไม่พบข้อมูล",
    INSUFFICENT_BALANCE: "ยอดคงเหลือไม่พอ",
    CLIENT_NOT_FOUND: "ไม่พบข้อมูลลูกค้า",
    USER_PASS_INCORRECT: "ยูสเซอร์เนมหรือรหัสผ่านไม่ถูกต้อง",
    USERNAME_EXIST: "ยูสเซอร์เนมนี้มีอยู่แล้ว โปรดใช้ยูสเซอร์เนมอื่น"
  }
};
