// USA
export const locale = {
  HEADER: {
    HI: "Hi,",
    SIGN_OUT: "Sign out"
  },
  MENU: {
    DASHBOARD: "Dashboard",
    TRANSACTION: "Transaction",
    RECENT_TRANSACTION: "Recent transaction",
    DEPOSIT: "Deposit",
    WITHDRAWAL: "Withdrawal",
    AGENT_LIST: "Agent list",
    CLIENT_LIST: "Client list",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "Operator List",
    CURRENCY_LIST: "Currency list",
    SETTING: "Setting",
    ROLE: "Role"
  },
  LOGIN: {
    USERNAME: "Username",
    PASSWORD: "Password",
    BUTTON: "Sign in"
  },
  TABLE: {
    INDEX: "Index",
    CLIENT_ID: "Client Id",
    NAME: "Name",
    USERNAME: "Username",
    BALANCE: "Balance",
    COMMISSION: "Commission",
    REQUEST: "Request",
    STATUS: "Status",
    FAILED: "FAILED",
    SUCCESS: "SUCCESS",
    PENDING: "PENDING",
    REJECTED: "REJECTED",
    PROCESSING: "PROCESSING",
    AMOUNT: "Amount ",
    RECEIVER_ADDRESS: "Receiver address",
    NETWORK: "Network",
    TRX_ID: "Trans ID",
    API_KEY: "API Key",
    SECRET_KEY: "Secret Key",
    SENDER_ADDRESS: "Sender address",
    TOKEN_TYPE: "Token type",
    TOKEN_NAME: "Token name",
    TYPE: "Type",
    DATE_TIME: "Date time",
    PER_PAGE: "Per page",
    ACTION: "Action",
    READ: "Read",
    CREATE: "Create",
    UPDATE: "Update",
    DELETE: "Delete",
    REMARKS: "Remarks",
    BLOCK_ID: "Block ID",
    WALLET_ADDRESS: "Wallet address",
    RECEIVE_AMOUNT: "Receive amount",
    UPDATED_AT: "Updated At",
    CREATED_AT: "Created At",
    ACCESS_KEY: "Access Key",
    NO_DATA: "No Data",
    ACTIVE: "ACTIVE",
    BANNED: "BANNED",
    INACTIVE: "Inactive",
    ENABLE: "Enable",
    DISABLE: "Disable",
    VIEW_ALL: "View All",
    COPY_ACCESS: "Copy access",
    ENABLE_2FA: "Enable 2fa",
    COPY_CLIPBOARD: "Has been copied to clipboard"
  },
  DASHBOARD: {
    AMOUNT_TRX: "Total daily amount of transaction",
    AMOUNT_WITHDRAW: "Total daily amount of withdrawal",
    NUMBER_TRX: "Total daily number of transaction",
    BALANCE: "Total balance amount in CrimsonPay"
  },
  SETTING: {
    CHANGE_PASS: "Change Password",
    UPDATE_GAS_FEE: "Update Gas Fee",
    GAS_FEE: "Gas Fee",
    NEW_PASSWORD: "New Password",
    CONFIRM_PASSWORD: "Confirm New Password",
    ENTER_NEW_PASSWORD: "Enter New Password",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "Include network fee"
  },
  TRANSACTION: {
    TRANS_LIST: "Transaction List",
    START_UP_DATE: "Start Updated Date",
    END_UP_DATE: "End Updated Date",
    SEARCH_FILTER: "Search (trx id, block id, amount)",
    SEARCH: "Search",
    NO_DATE: "No date selected",
    SENDER_ADDRESS: "Sender Address",
    TOKEN_TYPE: "Token Type",
    TOKEN_NAME: "Token Name",
    STATUS: "Status",
    ALL: "ALL",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    EXPORT: "Export"
  },
  MODAL: {
    OK: "OK",
    CANCEL: "Cancel",
    CREATE_SUCESSFULL: "Create is successfull",
    UPDATE_SUCESSFULL: "Update is successfull",
    DELETE_CONFIRMATION: "Are you Sure, want to delete",
    DELETE_SUCESSFULL: "Delete is successfull",
    FIELD_REQUIRED: "This field is required",
    FIELD_REQUIRED_NUMBER:
      "This field is required and must be a valid positive number",
    FIELD_REQUIRED_DECIMAL:
      "This field is required and must be greater than 0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "Confirm New Password",
    ENTER_PASSWORD: "Enter password",
    ENTER_PERCENTAGE: "Enter percentage",
    ENTER_NAME: "Enter name",
    ENTER_USERNAME: "Enter username",
    WILL_GENERATE: "Will be generated automatically",
    UPDATE: "Update",
    CREATE: "Create",
    CLIENT: "Client",
    OPERATOR: "Operator"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "Enter amount",
    ENTER_RECEIVER: "Enter receiver address",
    CURRENCY: "Currency",
    WITHDRAWAL_FEE: "Withdrawal fee",
    REQ_NEW_WITHDRAW: "Request New Withdrawal",
    UPDATE_STATUS: "Update Status",
    TRX_ID: "Trans ID",
    ENTER_TRX_ID: "Enter transaction ID",
    START_UP_DATE: "Start Updated Date",
    END_UP_DATE: "End Updated Date",
    SEARCH: "Search",
    NO_DATE: "No date selected",
    RECEIVER_ADDRESS: "Receiver Address",
    TRANS_ID: "Trans ID",
    NETWORK: "Network",
    STATUS: "Status",
    ALL: "ALL",
    PENDING: "PENDING",
    SUCCESS: "SUCCESS",
    FAILED: "FAILED",
    REJECTED: "REJECTED",
    PROCESSING: "PROCESSING",
    EXPORT: "Export",
    AMOUNT_VALIDATION: "Amount must be bigger from withdrawal fee"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "Please scan or enter code in your Google Authenticator:",
    UNABLE_SCAN:
      "If you are unable to scan the QR code, please enter this code manually into the app.",
    SAVE_KEY: "Save this Backup Key in a secure location",
    RECOVER:
      "This Key will allow you to recover your Authenticator should you lose your phone. Otherwise, resetting Google Authenticator will take at least 7 days.",
    VERIFY_ACCOUNT: "Verify your account",
    ENTER_DIGIT: "Enter the 6-digit code from Google Authenticator",
    VALIDATION_VERIFICATION_CODE: "Verification Code must be 6 digits number",
    INVALID_CODE: "Invalid google authenticator code, please try again",
    VALIDATOR_ENABLED: "2FA has been Enabled successfully!",
    VALIDATOR_DISABLED: "2FA has been Disabled successfully!",
    VALIDATION_CONFIRM_PASSWORD: "Confirm password not match with password"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "Are you Sure, want to disable",
    CONFIRMATION_ENABLE: "Are you Sure, want to enable"
  },
  ROLE: {
    ACCESS: "Access"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "Username not found",
    SERVER_ERROR: "Something wrong with server",
    DATA_NOT_FOUND: "Data not found",
    INSUFFICENT_BALANCE: "Insuffient Balance",
    CLIENT_NOT_FOUND: "Client data not found",
    USER_PASS_INCORRECT: "Username or password is incorrect",
    USERNAME_EXIST: "Username already exist, please try another one"
  }
};
