// USA
export const locale = {
  HEADER: {
    HI: "Xin chào,",
    SIGN_OUT: "Đăng xuất"
  },
  MENU: {
    DASHBOARD: "Bảng điều khiển",
    TRANSACTION: "Giao dịch",
    RECENT_TRANSACTION: "Giao dịch gần đây",
    DEPOSIT: "Nạp tiền",
    WITHDRAWAL: "Rút tiền",
    AGENT_LIST: "Danh sách Agent",
    CLIENT_LIST: "Danh sách Client",
    MEMBER_LIST: "Member list",
    OPERATOR_LIST: "Danh sách Operator",
    CURRENCY_LIST: "Danh sách tiền tệ",
    SETTING: "Tùy chỉnh",
    ROLE: "Vai trò"
  },
  LOGIN: {
    USERNAME: "Tên đăng nhập",
    PASSWORD: "Mật khẩu",
    BUTTON: "Đăng nhập"
  },
  TABLE: {
    INDEX: "Index",
    CLIENT_ID: "Mã Client",
    NAME: "Tên",
    USERNAME: "Tên đăng nhập",
    BALANCE: "Số dư",
    COMMISSION: "Hoa hồng",
    REQUEST: "Yêu cầu",
    STATUS: "Trạng thái",
    FAILED: "THẤT BẠI",
    SUCCESS: "THÀNH CÔNG",
    PENDING: "CHỜ XỬ LÝ",
    REJECTED: "TỪ CHỐI",
    PROCESSING: "ĐANG XỬ LÝ",
    AMOUNT: "Số tiền",
    RECEIVER_ADDRESS: "Địa chỉ người nhận",
    NETWORK: "Mạng lưới",
    TRX_ID: "Mã giao dịch",
    API_KEY: "Khóa API",
    SECRET_KEY: "Khóa bí mật",
    SENDER_ADDRESS: "Địa chỉ người nhận",
    TOKEN_TYPE: "Loại Token",
    TOKEN_NAME: "Tên Token",
    TYPE: "Lọai",
    DATE_TIME: "Ngày giờ",
    PER_PAGE: "Mỗi trang",
    ACTION: "Hành động",
    READ: "Đọc",
    CREATE: "Tạo",
    UPDATE: "Cập nhật",
    DELETE: "Xóa",
    REMARKS: "Ghi chú",
    BLOCK_ID: "Block ID",
    WALLET_ADDRESS: "Địa chỉ ví",
    RECEIVE_AMOUNT: "Số tiền nhận",
    UPDATED_AT: "Cập nhật lúc",
    CREATED_AT: "Tạo lúc",
    ACCESS_KEY: "Mã khóa truy cập",
    NO_DATA: "Không có dữ liệu",
    ACTIVE: "HOẠT ĐỘNG",
    BANNED: "BỊ KHÓA",
    INACTIVE: "Không hoạt động",
    ENABLE: "Kích hoạt",
    DISABLE: "Vô hiệu",
    VIEW_ALL: "Xem tất cả",
    COPY_ACCESS: "Sao chép truy cập",
    ENABLE_2FA: "Kích hoạt bảo mật 2 lớp",
    COPY_CLIPBOARD: "Đã sao chép vào bộ nhớ"
  },
  DASHBOARD: {
    AMOUNT_TRX: "Tổng giao dịch mỗi ngày",
    AMOUNT_WITHDRAW: "Tổng rút tiền mỗi ngày",
    NUMBER_TRX: "Tổng số giao dịch mỗi ngày",
    BALANCE: "Tổng số dư tài khoản tại CrimsonPay"
  },
  SETTING: {
    CHANGE_PASS: "Thay đổi mật khẩu",
    UPDATE_GAS_FEE: "Cập nhật phí Gas",
    GAS_FEE: "Phí Gas",
    NEW_PASSWORD: "Mật khẩu mới",
    CONFIRM_PASSWORD: "Xác nhận mật khẩu mới",
    ENTER_NEW_PASSWORD: "Điền mật khẩu mới",
    DESCRIPTION_NETWORK_FEE : "If toggled on, the company will shoulder the network fee; hence the withdrawal amount shown on the front end will be including the network fee.",
    INCLUDE_NETWORK_FEE: "Bao gồm phí mạng lưới"
  },
  TRANSACTION: {
    TRANS_LIST: "Danh sách giao dịch",
    START_UP_DATE: "Bắt đầu cập nhật Ngày",
    END_UP_DATE: "Kết thúc cập nhật Ngày",
    SEARCH_FILTER: "Tìm kiếm (trx id, mã khối, số tiền)",
    SEARCH: "Tìm kiếm",
    NO_DATE: "Chọn một ngày",
    SENDER_ADDRESS: "Địa chỉ người gửi",
    TOKEN_TYPE: "Loại Token",
    TOKEN_NAME: "Tên Token",
    STATUS: "Trạng thái",
    ALL: "TẤT CẢ",
    PENDING: "CHỜ XỬ LÝ",
    SUCCESS: "THÀNH CÔNG",
    FAILED: "THẤT BẠI",
    EXPORT: "Trích xuất"
  },
  MODAL: {
    OK: "ĐỒNG Ý",
    CANCEL: "Bỏ qua",
    CREATE_SUCESSFULL: "Tạo thành công",
    UPDATE_SUCESSFULL: "Cập nhật thành công",
    DELETE_CONFIRMATION: "Bạn có chắc muốn xóa không?",
    DELETE_SUCESSFULL: "Xóa thành công",
    FIELD_REQUIRED: "Thông tin này là bắt buộc",
    FIELD_REQUIRED_NUMBER: "Thông tin này là bắt buộc và phải là một số dương",
    FIELD_REQUIRED_DECIMAL:
      "Thông tin này là bắt buộc và phải là một số lớn hơn 0.1"
  },
  TIER: {
    CONFIRM_PASSWORD: "Xác nhận mật khẩu mới",
    ENTER_PASSWORD: "Điền mật khẩu mới",
    ENTER_PERCENTAGE: "Điền phần trăm",
    ENTER_NAME: "Điền tên",
    ENTER_USERNAME: "Điền tên đăng nhập",
    WILL_GENERATE: "Sẽ được tạo tự động",
    UPDATE: "Cập nhật",
    CREATE: "Tạo",
    CLIENT: "Client",
    OPERATOR: "Operator"
  },
  WITHDRAWAL: {
    ENTER_AMOUNT: "Điền số tiền",
    ENTER_RECEIVER: "Điền địa chỉ người nhận",
    CURRENCY: "Tiền tệ",
    WITHDRAWAL_FEE: "Phí rút tiền",
    REQ_NEW_WITHDRAW: "Yêu cầu rút tiền mới",
    UPDATE_STATUS: "Cập nhật trạng thái",
    TRX_ID: "Mã giao dịch",
    ENTER_TRX_ID: "Điền mã giao dịch",
    START_UP_DATE: "Bắt đầu cập nhật Ngày",
    END_UP_DATE: "Kết thúc cập nhật Ngày",
    SEARCH: "Tìm kiếm",
    NO_DATE: "Chọn một ngày",
    RECEIVER_ADDRESS: "Địa chỉ người nhận",
    TRANS_ID: "Mã giao dịch",
    NETWORK: "Mạng lưới",
    STATUS: "Trạng thái",
    ALL: "TẤT CẢ",
    PENDING: "CHỜ XỬ LÝ",
    SUCCESS: "THÀNH CÔNG",
    FAILED: "THẤT BẠI",
    REJECTED: "TỪ CHỐI",
    PROCESSING: "ĐANG XỬ LÝ",
    EXPORT: "Trích xuất",
    AMOUNT_VALIDATION: "Số tiền phải lớn hơn phí rút tiền"
  },
  AUTHENTICATOR: {
    PLEASE_SCAN: "Vui lòng quét hoặc nhận mã vào Google Authenticator:",
    UNABLE_SCAN:
      "Nếu bạn không thể quét mã QR, vui lòng nhập mã này theo cách thủ công vào ứng dụng.",
    SAVE_KEY: "Lưu mã khóa phục hồi này ở nơi an toàn",
    RECOVER:
      "Khóa này cho phép bạn khôi phục Authenticator nếu bị mất điện thoại. Nếu không việc cài đặt lại Google Authenticator sẽ mất ít nhất 7 ngày.",
    VERIFY_ACCOUNT: "Xác thực tài khoản",
    ENTER_DIGIT: "Điền mã Google Authenticator với 6 chữ số",
    VALIDATION_VERIFICATION_CODE: "Mã xác minh phải là số có 6 chữ số",
    INVALID_CODE: "Mã xác thực google không hợp lệ, vui lòng thử lại",
    VALIDATOR_ENABLED: "Mã xác thực 2 lớp đã được kích hoạt thành công!",
    VALIDATOR_DISABLED: "Mã xác thực 2 lớp đã được vô hiệu thành công!",
    VALIDATION_CONFIRM_PASSWORD: "Mật khẩu xác thực không khớp"
  },
  CURRENCY: {
    CONFIRMATION_DISABLE: "Bạn có chắc muốn vô hiệu không?",
    CONFIRMATION_ENABLE: "Bạn có chắc muốn kích hoạt không?"
  },
  ROLE: {
    ACCESS: "Truy cập"
  },
  ERROR: {
    USERNAME_NOT_FOUND: "Không tìm thấy tên đăng nhập",
    SERVER_ERROR: "Đã xảy ra lỗi với máy chủ",
    DATA_NOT_FOUND: "Không tìm thấy dữ liệu",
    INSUFFICENT_BALANCE: "Không đủ số dư",
    CLIENT_NOT_FOUND: "Không tìm thấy dữ liệu khách hàng",
    USER_PASS_INCORRECT: "Tên đăng nhập hoặc mật khẩu không chính xác",
    USERNAME_EXIST: "Tên đăng nhập đã tồn tại, vui lòng thử tên khác"
  }
};
